/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react'
import { CloudConnectorStatus } from 'blues-corejs/dist'
import { useNavigate, useParams } from 'react-router-dom'
import { List, ListItem, Typography, Box } from '@mui/material'
import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import Button from 'ui-v2/src/components/ui/inputs/button'
import { useDeploymentStore } from '../store/deployment-store'
import { RexClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { useToast } from 'ui-v2/src/hooks/toast'

const UnlinkCloudConnector = () => {
  const { action } = useParams()
  const isDrawerOpen =
    action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.UNLINK_CLOUD_CONNECTOR
  const navigate = useNavigate()
  const toast = useToast()
  const { cloudConnectors, rowData, setRowData } = useDeploymentStore()
  const rexClientGRPC = useGrpcClient(RexClient)
  const [isLoading, setIsLoading] = useState(false)

  const handleCloseDrawer = () => {
    setRowData(null)
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}`
    )
  }

  const handleUnlink = async () => {
    if (!rexClientGRPC || !rowData) {
      return
    }
    const { redStackIds } = rowData
    const requests: Array<Promise<any>> = []

    setIsLoading(true)
    cloudConnectors.forEach((model) => {
      const { redStackId } = model

      if (redStackIds.includes(redStackId)) {
        let baseRequest = Promise.resolve(true)

        if (
          CloudConnectorStatus.ACTIVE === model.status ||
          CloudConnectorStatus.UPGRADE_REQUIRED === model.status
        ) {
          baseRequest = rexClientGRPC.deactivate(redStackId)
        }
        requests.push(baseRequest.then(() => rexClientGRPC.delete(redStackId)))
      }
    })

    Promise.all(requests)
      .then(() => {
        toast.success('The sources has been unlinked')
      })
      .catch((error) => {
        toast.error(error.message ?? 'Failed to unlink')
      })
      .finally(() => {
        setIsLoading(false)
        handleCloseDrawer()
      })
  }

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
      <DrawerContent>
        <List sx={{ paddingX: 0 }}>
          <ListItem
            sx={{
              paddingX: 2.5,
              paddingRight: '70px',
            }}
            secondaryAction={[
              <Button variant="contained" onClick={handleCloseDrawer}>
                Close
              </Button>,
            ]}
          >
            <Typography fontWeight={700} fontSize={22}>
              Unlink Cloud Connector
            </Typography>
          </ListItem>
        </List>
        <Box
          sx={{
            paddingX: 2.5,
            paddingBottom: 2.5,
            position: 'relative',
          }}
        >
          <Typography variant="body1" fontWeight={500} mb={1}>
            Unlink will remove the following regions of this account from
            Elastio.
          </Typography>
          <Typography variant="body1" fontWeight={500} mb={1}>
            Unlink <b>will NOT</b> remove any Vaults or Cloud Connectors from
            your AWS account. To know more, see the{' '}
            <a
              href="https://support.elastio.com/hc/en-us"
              target="_blank"
              rel="noopener"
              style={{
                color: 'var(--blue500)',
              }}
            >
              Docs
            </a>
          </Typography>
          <Typography variant="body1" fontWeight={400} mb={2}>
            The regions to be removed are:{' '}
            <Typography component={'span'} variant="body1" fontWeight={700}>
              {rowData?.awsRegions.join(', ')}
            </Typography>
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Button variant="contained" onClick={handleCloseDrawer}>
              No, Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleUnlink}
              isLoading={isLoading}
            >
              Yes, Unlink
            </Button>
          </Box>
        </Box>
      </DrawerContent>
    </Drawer>
  )
}

export default UnlinkCloudConnector
