/* eslint-disable import/no-extraneous-dependencies */
import { uuid } from 'blues-corejs/dist/use_cases/tests/factories'
import { useNavigate, useParams } from 'react-router-dom'
import { List, ListItem, Typography, Box } from '@mui/material'
import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import Button from 'ui-v2/src/components/ui/inputs/button'
import ListComponents, {
  ListItems,
} from 'ui-v2/src/components/ui/data-display/list-components'

const listItems: Array<ListItems> = [
  {
    id: uuid(),
    text: 'Log in to the AWS Management Console',
    innerItems: [
      {
        id: uuid(),
        text: 'Navigate to the AWS account you want to unlink.',
      },
    ],
  },
  {
    id: uuid(),
    text: 'Open the CloudFormation Service',
    innerItems: [
      {
        id: uuid(),
        text: 'In the AWS Management Console, search for and open the CloudFormation service.',
      },
    ],
  },
  {
    id: uuid(),
    text: 'Locate the Elastio Protect Account CFN Stack',
    innerItems: [
      {
        id: uuid(),
        text: 'Find the CloudFormation stack that was used for the Protect Account setup.',
      },
      {
        id: uuid(),
        text: 'The stack name typically includes "ProtectAccount" or a similar identifier.',
      },
    ],
  },
  {
    id: uuid(),
    text: 'Delete the CFN Stack',
    innerItems: [
      {
        id: uuid(),
        text: 'Select the relevant CFN stack.',
      },
      {
        id: uuid(),
        text: 'Click on the Delete button.',
      },
      {
        id: uuid(),
        text: 'Confirm the deletion when prompted.',
      },
    ],
  },
  {
    id: uuid(),
    text: 'Verify Deletion',
    innerItems: [
      {
        id: uuid(),
        text: 'Wait for the stack deletion to complete.',
      },
      {
        id: uuid(),
        text: 'Ensure the status changes to DELETE_COMPLETE in the CloudFormation dashboard.',
      },
    ],
  },
]

const UnlinkProtectedAccount = () => {
  const { action } = useParams()
  const isDrawerOpen =
    action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.UNLINK_PROTECTED_ACCOUNT
  const navigate = useNavigate()

  const handleCloseDrawer = () => {
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}`
    )
  }

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
      <DrawerContent>
        <List sx={{ paddingX: 0 }}>
          <ListItem
            sx={{
              paddingX: 2.5,
              paddingRight: '70px',
            }}
            secondaryAction={
              <Button variant="contained" onClick={handleCloseDrawer}>
                Close
              </Button>
            }
          >
            <Typography fontWeight={700} fontSize={22}>
              Unlink Protected Account
            </Typography>
          </ListItem>
        </List>
        <Box
          sx={{
            paddingX: 2.5,
            paddingBottom: 2.5,
          }}
        >
          <Box mb={2}>
            <Typography variant="body1" fontWeight={500} mb={1}>
              To unlink a protected account, you need to delete the Protect
              Account CloudFormation (CFN) stack from the account. Follow these
              steps:
            </Typography>
            <ListComponents wrapperComponent={'ol'} items={listItems} />
          </Box>
          <Typography
            variant="body1"
            color="var(--blue500)"
            fontWeight={700}
            mb={2}
          >
            Important Note
          </Typography>
          <Typography variant="body1" fontWeight={500} mb={1}>
            Unlinking a protected account will stop ransomware protection for
            resources in that account. Ensure you no longer require protection
            for the account before deleting the CFN stack.
          </Typography>
        </Box>
      </DrawerContent>
    </Drawer>
  )
}

export default UnlinkProtectedAccount
