import { useMemo } from 'react'
import { Box, Card, Stack, Typography } from '@mui/material'
import { useRpoAndRetentionStatsQuery } from 'ui-v2/src/hooks/queries/aws-stats'
import dayjs from 'dayjs'
import GradientCard from 'ui-v2/src/components/ui/cards/gradient'

type MetricCardProps = {
  metric: number
  label: string
}

const MetricCard = ({ metric, label }: MetricCardProps) => {
  return (
    <GradientCard
      sx={{
        height: 100,
        width: 130,
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Stack
        alignItems={'center'}
        p={2}
        spacing={0}
        justifyContent={'center'}
        height={'100%'}
      >
        <Typography fontSize={36} lineHeight={'43px'}>
          {metric}
        </Typography>
        <Typography fontSize={14}>{label}</Typography>
      </Stack>
    </GradientCard>
  )
}

function MedianRpoCard() {
  const { data: rpoAndRetentionData } = useRpoAndRetentionStatsQuery({})

  const rpoData = useMemo(() => {
    const defaultData = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }

    if (!rpoAndRetentionData) {
      return defaultData
    }

    const rpoQuantiles =
      rpoAndRetentionData.rpoStats?.rpoStatsQuantilesList ?? []

    const fortyPercentile = rpoQuantiles.find((q) => q.percentile === 40)?.age
    const sixtyPercentile = rpoQuantiles.find((q) => q.percentile === 60)?.age

    if (!fortyPercentile || !sixtyPercentile) {
      return defaultData
    }

    const medianInSeconds = (fortyPercentile + sixtyPercentile) / 2

    const duration = dayjs.duration(medianInSeconds)

    const days = Math.floor(duration.asDays())
    const hours = duration.hours()
    const minutes = duration.minutes()
    const seconds = duration.seconds()

    return {
      days,
      hours,
      minutes,
      seconds,
    }
  }, [rpoAndRetentionData])

  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        paddingX: 2.5,
        paddingY: 2,
        borderRadius: 2.5,
        background: theme.palette.background.secondary,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <Typography
        fontSize={16}
        fontWeight={700}
        mb={1.5}
        color="text.secondary"
      >
        Median Recovery Assurance Lag
      </Typography>
      <Box
        display={'flex'}
        flexDirection={'row'}
        columnGap={2.5}
        justifyContent={'center'}
        alignItems={'center'}
        flexGrow={1}
      >
        <Stack direction={'row'} gap={1}>
          <MetricCard label={'Days'} metric={rpoData.days} />
          <MetricCard label={'Hours'} metric={rpoData.hours} />
          <MetricCard label={'Minutes'} metric={rpoData.minutes} />
        </Stack>
      </Box>
    </Card>
  )
}

export default MedianRpoCard
