import * as echarts from 'echarts'
import ElastioLogo from 'ui-v2/src/assets/logo/elastio-logo.svg'
import { RadarChartAxes } from 'ui-v2/src/lib/constants/ui.constant'

export function radarChartOptionsInitial(
  recoveryAssurancePercentage: number,
  assetCoveragePercentage: number,
  ransomwareSafetyPercentage: number,
  encryptionIntegrityPercentage: number,
  storageHealthPercentage: number,
  selectedSection: RadarChartAxes,
  setSelectedSection: (section: RadarChartAxes) => void
) {
  const indicators = [
    {
      name: '',
      max: 100,
    },
    {
      name: '',
      max: 100,
    },
    {
      name: '',
      max: 100,
    },
    {
      name: '',
      max: 100,
    },
    {
      name: '',
      max: 100,
    },
  ]

  const option = {
    title: {
      text: '',
    },

    radar: {
      shape: 'circle',
      splitNumber: 1,
      indicator: indicators,
      axisName: {
        color: '#B2B5C1',
        fontSize: 14,
        fontWeight: 400,

        rich: {
          a: {
            fontSize: 16,
            fontWeight: 400,
            align: 'center',
            cursor: 'pointer',
          },
        },
      },

      splitArea: {
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0.1, 0, 0.1, 1, [
            {
              offset: 0,
              color: 'rgba(117, 125, 132, 0.1)',
            },
            {
              offset: 1,
              color: 'rgba(117, 125, 132, 0.1)',
            },
            {
              offset: 0,
              color: 'rgba(117, 125, 132, 0.1)',
            },
          ]),
        },
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.2)',
        },
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.2)',
        },
      },
    },
    axisPointer: {
      show: true,
      lineStyle: {
        color: '#aaa',
        width: 1,
        type: 'dashed',
      },
    },
    series: [
      {
        type: 'radar',
        data: [
          {
            value: [
              recoveryAssurancePercentage ?? 0,
              assetCoveragePercentage ?? 0,
              ransomwareSafetyPercentage ?? 0,
              encryptionIntegrityPercentage ?? 0,
              storageHealthPercentage ?? 0,
            ],
            name: '360 Resilience',
            areaStyle: {
              normal: {
                color: new echarts.graphic.RadialGradient(0.5, 0.5, 0.5, [
                  {
                    offset: 0,
                    color: 'rgba(0, 0, 0, 0)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(41, 133, 220, 0.45)',
                  },
                ]),
              },
              emphasis: {
                color: '#87c0de',
                opacity: 0.9,
              },
            },
            lineStyle: {
              color: 'rgba(0, 100, 193, 0.40)',
              width: 2,
            },
            symbol: 'circle',
            symbolSize: 0,
          },
        ],
      },
    ],
    graphic: [
      {
        type: 'image',
        left: 'center',
        top: 'center',
        z: 0,
        style: {
          image: ElastioLogo.src,
          width: 40,
          height: 40,
          opacity: 1,
        },
      },
      {
        type: 'text',
        left: '37%',
        top: '5%',
        color: '#fff',
        style: {
          text: '30% Recovery Assurance',
          fontSize: 16,
          cursor: 'pointer',
          fill:
            selectedSection === RadarChartAxes.RECOVERY_ASSURANCE
              ? '#80C5FF'
              : '#B2B5C1',
          textAlign: 'center',
          textBaseline: 'middle',
        },
        onclick: function () {
          setSelectedSection(RadarChartAxes.RECOVERY_ASSURANCE)
        },
      },
      {
        type: 'text',
        left: '8%',
        top: '35%',
        color: '#fff',
        style: {
          text: '30% \nStorage Health',
          fontSize: 16,
          cursor: 'pointer',
          fill:
            selectedSection === RadarChartAxes.STORAGE_HEALTH
              ? '#80C5FF'
              : '#B2B5C1',
          textAlign: 'right',
          textBaseline: 'middle',
        },
        onclick: function () {
          setSelectedSection(RadarChartAxes.STORAGE_HEALTH)
        },
      },
      {
        type: 'text',
        left: '76%',
        top: '35%',
        color: '#fff',
        style: {
          text: '30% \nAsset Coverage',
          fontSize: 16,
          cursor: 'pointer',
          fill:
            selectedSection === RadarChartAxes.ASSET_COVERAGE
              ? '#80C5FF'
              : '#B2B5C1',
          textAlign: 'left',
          textBaseline: 'middle',
        },
        onclick: function () {
          setSelectedSection(RadarChartAxes.ASSET_COVERAGE)
        },
      },
      {
        type: 'text',
        left: '12%',
        top: '80%',
        color: '#fff',
        style: {
          text: '30% \nEncryption Activity',
          fontSize: 16,
          cursor: 'pointer',
          fill:
            selectedSection === RadarChartAxes.ENCRYPTION_INTEGRITY
              ? '#80C5FF'
              : '#B2B5C1',
          textAlign: 'right',
          textBaseline: 'middle',
        },
        onclick: function () {
          setSelectedSection(RadarChartAxes.ENCRYPTION_INTEGRITY)
        },
      },
      {
        type: 'text',
        left: '68%',
        top: '80%',
        color: '#fff',
        style: {
          text: '30%\nRansomware Safety',
          fontSize: 16,
          cursor: 'pointer',
          fill:
            selectedSection === RadarChartAxes.RANSOMWARE_SAFETY
              ? '#80C5FF'
              : '#B2B5C1',
          textAlign: 'left',
          textBaseline: 'middle',
        },
        onclick: function () {
          setSelectedSection(RadarChartAxes.RANSOMWARE_SAFETY)
        },
      },
    ],
  }

  return option
}

export function radarChartOptions(
  recoveryAssurancePercentage: number,
  assetCoveragePercentage: number,
  ransomwareSafetyPercentage: number,
  encryptionIntegrityPercentage: number,
  storageHealthPercentage: number,
  selectedSection: RadarChartAxes,
  setSelectedSection: (section: RadarChartAxes) => void,
  isLoading: boolean
) {
  return {
    radar: {
      shape: 'circle',
      splitNumber: 1,

      splitArea: {
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0.1, 0, 0.1, 1, [
            {
              offset: 0,
              color: 'rgba(117, 125, 132, 0.1)',
            },
            {
              offset: 1,
              color: 'rgba(117, 125, 132, 0.1)',
            },
            {
              offset: 0,
              color: 'rgba(117, 125, 132, 0.1)',
            },
          ]),
        },
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.2)',
        },
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.2)',
        },
      },
    },
    axisPointer: {
      show: true,
      lineStyle: {
        color: '#aaa',
        width: 1,
        type: 'dashed',
      },
    },
    series: [
      {
        type: 'radar',
        data: [
          {
            value: isLoading
              ? [0, 0, 0, 0, 0]
              : [
                  recoveryAssurancePercentage ?? 0,
                  storageHealthPercentage ?? 0,
                  encryptionIntegrityPercentage ?? 0,
                  ransomwareSafetyPercentage ?? 0,
                  assetCoveragePercentage ?? 0,
                ],
            name: '360 Resilience',
            areaStyle: {
              normal: {
                color: new echarts.graphic.RadialGradient(0.5, 0.5, 0.5, [
                  {
                    offset: 0,
                    color: 'rgba(0, 0, 0, 0)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(41, 133, 220, 0.45)',
                  },
                ]),
              },
              emphasis: {
                color: '#87c0de',
                opacity: 0.9,
              },
            },
            lineStyle: {
              color: 'rgba(0, 100, 193, 0.40)',
              width: 2,
            },
            symbol: 'circle',
            symbolSize: 0,
          },
        ],
      },
    ],
    graphic: [
      {
        type: 'image',
        left: 'center',
        top: 'center',
        z: 0,
        style: {
          image: ElastioLogo.src,
          width: 40,
          height: 40,
          opacity: 1,
        },
      },
      {
        type: 'text',
        left: '37%',
        top: '5%',
        color: '#fff',
        style: {
          text: `${recoveryAssurancePercentage ?? 0}% Recovery Assurance`,
          fontSize: 16,
          cursor: 'pointer',
          fill:
            selectedSection === RadarChartAxes.RECOVERY_ASSURANCE
              ? '#80C5FF'
              : '#B2B5C1',
          textAlign: 'center',
          textBaseline: 'middle',
        },
        onclick: function () {
          setSelectedSection(RadarChartAxes.RECOVERY_ASSURANCE)
        },
      },
      {
        type: 'text',
        left: '8%',
        top: '35%',
        color: '#fff',
        style: {
          text: `${storageHealthPercentage ?? 0}% \nStorage Health`,
          fontSize: 16,
          cursor: 'pointer',
          fill:
            selectedSection === RadarChartAxes.STORAGE_HEALTH
              ? '#80C5FF'
              : '#B2B5C1',
          textAlign: 'right',
          textBaseline: 'middle',
        },
        onclick: function () {
          setSelectedSection(RadarChartAxes.STORAGE_HEALTH)
        },
      },
      {
        type: 'text',
        left: '76%',
        top: '35%',
        color: '#fff',
        style: {
          text: `${assetCoveragePercentage ?? 0}% \nAsset Coverage`,
          fontSize: 16,
          cursor: 'pointer',
          fill:
            selectedSection === RadarChartAxes.ASSET_COVERAGE
              ? '#80C5FF'
              : '#B2B5C1',
          textAlign: 'left',
          textBaseline: 'middle',
        },
        onclick: function () {
          setSelectedSection(RadarChartAxes.ASSET_COVERAGE)
        },
      },
      {
        type: 'text',
        left: '12%',
        top: '80%',
        color: '#fff',
        style: {
          text: `${encryptionIntegrityPercentage ?? 0}% \nEncryption Activity`,
          fontSize: 16,
          cursor: 'pointer',
          fill:
            selectedSection === RadarChartAxes.ENCRYPTION_INTEGRITY
              ? '#80C5FF'
              : '#B2B5C1',
          textAlign: 'right',
          textBaseline: 'middle',
        },
        onclick: function () {
          setSelectedSection(RadarChartAxes.ENCRYPTION_INTEGRITY)
        },
      },
      {
        type: 'text',
        left: '68%',
        top: '80%',
        color: '#fff',
        style: {
          text: `${ransomwareSafetyPercentage ?? 0}%\nRansomware Safety`,
          fontSize: 16,
          cursor: 'pointer',
          fill:
            (ransomwareSafetyPercentage ?? 0) < 100
              ? selectedSection === RadarChartAxes.RANSOMWARE_SAFETY
                ? '#FF1654' // can change this if need to update active color when there are infected assets
                : '#FF1654'
              : selectedSection === RadarChartAxes.RANSOMWARE_SAFETY
                ? '#80C5FF'
                : '#B2B5C1',
          textAlign: 'left',
          textBaseline: 'middle',
        },
        onclick: function () {
          setSelectedSection(RadarChartAxes.RANSOMWARE_SAFETY)
        },
      },
    ],
  }
}
