import { useState } from 'react'
import SignoutDrawer from 'ui-v2/src/components/dialogs/signout'
import { useAuth0 } from '@auth0/auth0-react'
import {
  AccountCircleOutlined,
  DirectionsRun,
  LoginOutlined,
  ManageAccountsOutlined,
} from '@mui/icons-material'
import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { BASE_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  const { user } = useAuth0()
  const navigate = useNavigate()

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleClickDialogClose = () => {
    setDialogOpen(false)
  }

  const handleOpenAccountSettings = () => {
    setDialogOpen(false)
    navigate(`/${BASE_ROUTE_SEGMENTS.ACCOUNTS_SETTINGS}`)
  }

  const handleOpenOnboarding = () => {
    setDialogOpen(false)
    navigate(`/${BASE_ROUTE_SEGMENTS.ONBOARDING}`)
  }

  return (
    <>
      <Box
        width={'100%'}
        maxWidth={500}
        display={'flex'}
        justifyContent={'flex-end'}
        columnGap={2}
      >
        <IconButton
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Avatar
            src={user?.picture}
            alt={user?.name}
            sx={{ bgcolor: 'transparent' }}
          >
            <AccountCircleOutlined
              fontSize="medium"
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
              }}
            />
          </Avatar>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          slotProps={{
            paper: {
              elevation: 0,
            },
          }}
        >
          <MenuItem onClick={handleOpenAccountSettings}>
            <ListItemIcon>
              <ManageAccountsOutlined fontSize="small" />
            </ListItemIcon>
            My Profile
          </MenuItem>
          <MenuItem onClick={handleOpenOnboarding}>
            <ListItemIcon>
              <DirectionsRun fontSize="small" />
            </ListItemIcon>
            Onboarding
          </MenuItem>
          <MenuItem onClick={handleClickDialogOpen}>
            <ListItemIcon>
              <LoginOutlined fontSize="small" />
            </ListItemIcon>
            Sign Out
          </MenuItem>
        </Menu>
      </Box>
      <SignoutDrawer open={dialogOpen} onClose={handleClickDialogClose} />
    </>
  )
}

export default AccountMenu
