/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useMemo } from 'react'
import { Stack } from '@mui/material'

import { PolicyEc2EbsTableData } from 'ui-v2/src/lib/use-cases/settings/policies/ec2-ebs-table-data'

import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import { convertBackupsToMap } from 'ui-v2/src/lib/helpers/policies.helper'
import { BackupProcessor } from 'ui-v2/src/lib/models/assets/backup-processor'
import { getEc2EbsColumns } from '../column-config'
import { LiveAssets } from 'ui-v2/src/lib/models/assets'
import { AssetsForPolicyCoverage } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import {
  PolicyEc2EbsTableRow,
  PolicyFormData,
} from 'ui-v2/src/lib/models/settings/policies/policies'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

const AssetsEbs = ({
  liveAssetsData,
  ebsAssets,
  isLoading,
  handleAssetsSelect,
}: AssetsEbsProps) => {
  const { id } = useParams()
  const { watch, getValues, setValue, trigger } =
    useFormContext<PolicyFormData>()

  const selectedEc2EbsAssetsRows = watch('selectedEc2EbsAssetsRows')

  const data = useMemo(() => {
    const lastBackup = convertBackupsToMap(
      liveAssetsData.lastBackups as unknown as BackupProcessor
    )

    return new PolicyEc2EbsTableData({
      ec2EbsAssets: ebsAssets,
      lastBackup,
    }).getEc2AssetsList()
  }, [liveAssetsData, ebsAssets])

  useEffect(() => {
    if (id && data) {
      const selectedAssets = getValues('selectedAssets')
      const selectedAssetsIds = selectedAssets.map((asset) => asset.asset.id)
      const selectedEc2EbsRows = data.filter((row) =>
        selectedAssetsIds.includes(row.id)
      )

      setValue('selectedEc2EbsAssetsRows', selectedEc2EbsRows)
    }
  }, [id, data])

  const columns = useMemo(getEc2EbsColumns, [])

  return (
    <Stack spacing={1}>
      <ClientTable
        checkable
        data={data ?? []}
        columns={columns}
        isLoading={isLoading}
        selectedRows={selectedEc2EbsAssetsRows}
        onSelectRows={(rows) => {
          handleAssetsSelect(rows)
          trigger('assetVariant')
        }}
      />
    </Stack>
  )
}
interface AssetsEbsProps {
  liveAssetsData: LiveAssets
  ebsAssets: AssetsForPolicyCoverage
  isLoading: boolean
  handleAssetsSelect: (rows: Array<PolicyEc2EbsTableRow>) => void
}
export default AssetsEbs
