/* eslint-disable import/no-cycle */
import { useState } from 'react'
import { Grid2, Typography } from '@mui/material'
import RadarChart from '../dashboard-charts/radar-chart'
import GradientCard from 'ui-v2/src/components/ui/cards/gradient'
import { RadarChartAxes } from 'ui-v2/src/lib/constants/ui.constant'
import AssetsScanned from './assets-scanned'
import FilesScanned from './files-sanned'
import ResilienceScoreDrawer from 'ui-v2/src/components/drawers/resilience-score'
import DataScanned from './data-scanned'

type RadarChartProps = {
  selectedSection: RadarChartAxes
  setSelectedSection: (section: RadarChartAxes) => void
}

export type ResilienceWeightData = {
  recoveryAssuranceWeight: number
  ransomwareSafetyWeight: number
  assetCoverageWeight: number
  encryptionIntegrityWeight: number
  storageHealthWeight: number
}

export type ResiliencePercentage = {
  assetCoveragePercentage: number
  ransomwareSafetyPercentage: number
  encryptionIntegrityPercentage: number
  recoveryAssurancePercentage: number
  storageHealthPercentage: number
}

const getStoredResilienceData = (): ResilienceWeightData => {
  const storedData = localStorage.getItem('resilienceData')
  if (storedData) {
    return JSON.parse(storedData)
  }
  return {
    recoveryAssuranceWeight: 100,
    ransomwareSafetyWeight: 100,
    assetCoverageWeight: 100,
    encryptionIntegrityWeight: 100,
    storageHealthWeight: 100,
  }
}

const RadarChartCard = ({
  selectedSection,
  setSelectedSection,
}: RadarChartProps) => {
  const [resilienceScore, setResilienceScore] = useState<number>(0)
  const [resilienceWeight, setResilienceWeight] =
    useState<ResilienceWeightData>(getStoredResilienceData())
  const [resiliencePercentage, setResiliencePercentage] =
    useState<ResiliencePercentage>()

  const [openScoreCalculationDrawer, setOpenScoreCalculationDrawer] =
    useState(false)

  const closeScoreCalculationDrawer = () => {
    setOpenScoreCalculationDrawer(false)
  }

  return (
    <>
      <GradientCard>
        <Typography
          fontSize={32}
          fontWeight={700}
          mb={2}
          color="text.secondary"
        >
          Ransomware Resilience Score &nbsp;
          <Typography
            fontSize={32}
            fontWeight={700}
            sx={(theme) => ({
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.common.white
                  : theme.palette.text.primary,
            })}
            component={'span'}
          >
            {Math.round(resilienceScore)}%
          </Typography>
        </Typography>
        <Grid2 container spacing={2.5}>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 3,
            }}
            sx={{
              display: {
                xs: 'block',
                sm: 'flex',
                md: 'flex',
                lg: 'block',
              },
              gap: {
                sm: 1.5,
                md: 1.5,
                lg: 0,
              },
            }}
          >
            <AssetsScanned />
            <FilesScanned />

            <DataScanned />
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 9,
            }}
            justifyContent={'center'}
          >
            <RadarChart
              selectedSection={selectedSection}
              setSelectedSection={setSelectedSection}
              resilienceScore={resilienceScore}
              setResilienceScore={setResilienceScore}
              resilienceWeight={resilienceWeight}
              resiliencePercentage={resiliencePercentage}
              setResiliencePercentage={setResiliencePercentage}
              setOpenScoreCalculationDrawer={setOpenScoreCalculationDrawer}
            />
          </Grid2>
        </Grid2>
      </GradientCard>
      <ResilienceScoreDrawer
        key={openScoreCalculationDrawer.toString()}
        openScoreCalculationDrawer={openScoreCalculationDrawer}
        closeScoreCalculationDrawer={closeScoreCalculationDrawer}
        setResilienceScore={setResilienceScore}
        resilienceWeight={resilienceWeight}
        setResilienceWeight={setResilienceWeight}
        resiliencePercentage={resiliencePercentage}
      />
    </>
  )
}

export default RadarChartCard
