import { useMemo } from 'react'
import { Typography } from '@mui/material'
import CircleBarChart from '../dashboard-charts/circle-bar-chart'
import GradientCard from 'ui-v2/src/components/ui/cards/gradient'
import { useAwsScanCoverageQuery } from 'ui-v2/src/hooks/queries/aws-stats'

export const AssetCoverage = () => {
  const { data: awsScanCoverageData } = useAwsScanCoverageQuery({})

  const services = ['ebs', 'ec2', 'efs', 's3'] as const

  const chartData = useMemo(() => {
    if (!awsScanCoverageData) {
      return []
    }

    const efsCoveredCount = awsScanCoverageData.efs?.covered ?? 0
    const efsCoverage =
      Math.round(
        (efsCoveredCount / (awsScanCoverageData.efs?.total || 1)) * 100
      ) || 0

    const ebsCoveredCount = awsScanCoverageData.ebs?.covered ?? 0
    const ebsCoverage =
      Math.round(
        (ebsCoveredCount / (awsScanCoverageData.ebs?.total || 1)) * 100
      ) || 0

    const s3CoveredCount = awsScanCoverageData.s3?.covered ?? 0
    const s3Coverage =
      Math.round(
        (s3CoveredCount / (awsScanCoverageData.s3?.total || 1)) * 100
      ) || 0

    const ec2CoveredCount = awsScanCoverageData.ec2?.covered ?? 0
    const ec2Coverage =
      Math.round(
        (ec2CoveredCount / (awsScanCoverageData.ec2?.total || 1)) * 100
      ) || 0

    return [
      {
        name: `{bold|${efsCoveredCount}} EFS Filesystem  {bold|${efsCoverage}%}`,
        value: efsCoverage,
      },
      {
        name: `{bold|${ebsCoveredCount}} EBS Volumes  {bold|${ebsCoverage}%}`,
        value: ebsCoverage,
      },
      {
        name: `{bold|${s3CoveredCount}} S3 Buckets  {bold|${s3Coverage}%}`,
        value: s3Coverage,
      },
      {
        name: `{bold|${ec2CoveredCount}} EC2 Instances  {bold|${ec2Coverage}%}`,
        value: ec2Coverage,
      },
    ]
  }, [awsScanCoverageData])

  const assetCoveragePercentage = useMemo(() => {
    if (!awsScanCoverageData) {
      return 0
    }

    const awsCoveredCount = services.reduce(
      (sum, service) => sum + (awsScanCoverageData[service]?.covered ?? 0),
      0
    )

    const awsTotalCount = services.reduce(
      (sum, service) => sum + (awsScanCoverageData[service]?.total ?? 0),
      0
    )

    return Math.round((awsCoveredCount / (awsTotalCount || 1)) * 100)
  }, [awsScanCoverageData])

  return (
    <GradientCard>
      <Typography fontSize={16} fontWeight={700} mb={1} color="text.secondary">
        Asset Coverage
      </Typography>
      <CircleBarChart total={assetCoveragePercentage} data={chartData} />
    </GradientCard>
  )
}

export default AssetCoverage
