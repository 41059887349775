import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import ActiveDeployments from './active-deployments'
import Button from 'ui-v2/src/components/ui/inputs/button'

interface TabPanelProps {
  children?: React.ReactNode
  value: string
  slug: string
}

function TabPanel(props: TabPanelProps) {
  const { children, value, slug, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== slug}
      id={`tabpanel-${slug}`}
      aria-labelledby={`tab-${slug}`}
      {...other}
    >
      {value === slug && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(slug: string) {
  return {
    id: `tab-${slug}`,
    'aria-controls': `tabpanel-${slug}`,
  }
}

const SUB_TAB_MAP = {
  tenant: {
    label: 'Active Deployments',
    component: <ActiveDeployments />,
    slug: GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS,
  },
  'account-profile': {
    label: 'Activity Log',
    component: <></>,
    slug: GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVITY_LOG,
  },
}

const Sources = () => {
  const navigate = useNavigate()
  const { subTab } = useParams<{
    subTab: (typeof GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS)[keyof typeof GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS]
  }>()

  const defaultPath = `/settings/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}`

  useEffect(() => {
    if (!subTab) {
      navigate(defaultPath, { replace: true })
      return
    }

    if (!Object.values(SUB_TAB_MAP).some((t) => t.slug === subTab)) {
      navigate(defaultPath, { replace: true })
      return
    }
  }, [])

  if (!subTab || !Object.values(SUB_TAB_MAP).some((t) => t.slug === subTab)) {
    return null
  }

  const handleChange = (
    _: React.SyntheticEvent,
    newSlug: (typeof GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS)[keyof typeof GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS]
  ) => {
    navigate(`/settings/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${newSlug}`)
  }

  const handleLinkSource = () => {
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.LINK_SOURCE}`
    )
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderBottomColor: 'divider',
            flex: 1,
          }}
        >
          <Tabs
            value={subTab}
            onChange={handleChange}
            sx={(theme) => ({
              '& .MuiTab-root.Mui-selected': {
                color: theme.palette.text.primary,
              },
            })}
          >
            {Object.values(SUB_TAB_MAP).map((subTabItem) => (
              <Tab
                key={subTabItem.slug}
                value={subTabItem.slug}
                label={subTabItem.label}
                sx={{ textTransform: 'capitalize' }}
                {...a11yProps(subTabItem.slug)}
              />
            ))}
          </Tabs>
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={{ textTransform: 'capitalize' }}
          onClick={handleLinkSource}
        >
          Deploy
        </Button>
      </Box>
      {Object.entries(SUB_TAB_MAP).map(([key, value]) => (
        <TabPanel key={key} value={subTab} slug={value.slug}>
          {value.component}
        </TabPanel>
      ))}
    </Box>
  )
}

export default Sources
