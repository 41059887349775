import { useEffect, useMemo, useState } from 'react'
import {
  IconButton,
  Stack,
  Typography,
  Box,
  ListItem,
  List,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import AwsAccount from './aws-form/aws-account'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'

import { FormProvider, useForm } from 'react-hook-form'
import { SourceFormData } from 'ui-v2/src/lib/models/settings/sources/sources'
import {
  useDiscoverInstalledCfnQuery,
  useListSupportedAwsRegionsQuery,
} from 'ui-v2/src/hooks/queries/red-stack'
import Button from 'ui-v2/src/components/ui/inputs/button'
import AwsAccountElastio from './aws-form/aws-account-elastio'
import { useAccountLevelCloudFormationLink } from 'ui-v2/src/hooks/queries/red-stack/account-level-cloud-formation-link'
import { useUpdateConnectorAccountInstallFlow } from 'ui-v2/src/hooks/queries/red-stack/update-level-cloud-formation-link'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { RexClient } from 'ui-v2/src/lib/clients'
import { toast } from 'react-toastify'
import { CfnStatusConstant } from 'ui-v2/src/lib/constants/cfn-status.constant'

const AwsDrawer = () => {
  const rexClientGRPC = useGrpcClient(RexClient)
  const [isDeploymentLoading, setDeploymentLoading] = useState(false)
  const { data: listSupportedAwsRegionsData } =
    useListSupportedAwsRegionsQuery()
  const { action } = useParams()
  const isDrawerOpen = action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.LINK_SOURCE
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState<number>(0)
  const [previousEmittedAccountId, setPreviousEmittedAccountId] =
    useState<string>('')
  const [disabledVpc, setDisabledVpc] = useState(true)
  const [maxSteps, setMaxSteps] = useState<number>(2)
  const [shouldInstallCfn, setShouldInstallCfn] = useState(false)

  const method = useForm<SourceFormData>({
    defaultValues: {
      awsAccountId: '',
      awsRegionId: '',
      isVpcAutoCreate: false,
      vpcRegions: [],
    },
  })
  const isVPCAutocreate = method.watch('isVpcAutoCreate')

  const {
    mutate: discoverInstalledCfnMutate,
    isPending: discoverInstalledCfnIsPending,
  } = useDiscoverInstalledCfnQuery()
  const {
    mutate: requestAccountLevelCloudFormationLink,
    isPending: isPendingAccountLevelCFL,
    isError,
    isSuccess,
  } = useAccountLevelCloudFormationLink()
  const isFinishedAccountLevelCFL =
    isError || (isSuccess && !isPendingAccountLevelCFL)
  const { mutate: updateConnectorAccountInstallFlow } =
    useUpdateConnectorAccountInstallFlow()

  const { handleSubmit, reset } = method

  const handleCloseDrawer = () => {
    setActiveStep(0)
    setPreviousEmittedAccountId('')
    reset()
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}`
    )
  }

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1)
  }

  const handleCreate = (data: SourceFormData) => {
    if (!rexClientGRPC) {
      return
    }

    setDeploymentLoading(true)
    const { getValues } = method
    const { awsAccountId } = getValues()
    const requests = data.vpcRegions.map(() =>
      rexClientGRPC.installRedstack(
        awsAccountId,
        't.vpcId',
        't.regionName',
        false,
        []
      )
    )

    Promise.all(requests)
      .then(() => {
        toast.success('Deployment success')
      })
      .catch((error) => {
        toast.error(error.message ?? 'Cannot perform deployment')
      })
      .finally(() => {
        setDeploymentLoading(false)
        handleCloseDrawer()
      })
  }

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  useEffect(() => {
    if (isVPCAutocreate) {
      setMaxSteps(2)
    } else {
      setMaxSteps(3)
    }
  }, [isVPCAutocreate])

  const submit = async (sourceFormData: SourceFormData) => {
    if (activeStep === 0) {
      const { awsAccountId, isVpcAutoCreate, vpcRegions } = method.getValues()
      await updateConnectorAccountInstallFlow({
        awsAccountId,
        isVpcAutoCreate,
        vpcRegions,
      })
    }

    if (maxSteps === 5) {
      handleCreate(sourceFormData)
    }

    if (activeStep === maxSteps - 1) {
    } else {
      handleNext()
    }
  }

  const disabled = useMemo(() => {
    if (shouldInstallCfn) {
      return true
    }
    if (activeStep === 0) {
      return false
    }
    return false
  }, [activeStep])

  const onAccountIdChange = (awsAccountId: string) => {
    requestAccountLevelCloudFormationLink(awsAccountId, {
      onSuccess: (data) => {
        if (data) {
          setDisabledVpc(false)
          method.setValue('isVpcAutoCreate', true)
        } else {
          setDisabledVpc(true)
          method.setValue('isVpcAutoCreate', false)
        }

        discoverInstalledCfnMutate(awsAccountId, {
          onSuccess: (cfnData) => {
            if (CfnStatusConstant.INSTALLED !== cfnData.status) {
              setShouldInstallCfn(true)
            }
          },
          onError: (error) => {
            setShouldInstallCfn(true)
            console.log(error.message)
          },
        })
      },
      onError: () => {
        setDisabledVpc(false)
        method.setValue('isVpcAutoCreate', true)
      },
    })
  }

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
      <DrawerContent>
        <FormProvider {...method}>
          <form onSubmit={handleSubmit(submit)}>
            <List sx={{ paddingX: 0 }}>
              <ListItem
                sx={{
                  paddingX: 2.5,
                  paddingRight: '220px',
                }}
                secondaryAction={
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    {activeStep > 0 && (
                      <Button
                        type="button"
                        color="inherit"
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={disabled}
                      isLoading={
                        isPendingAccountLevelCFL ||
                        isDeploymentLoading ||
                        discoverInstalledCfnIsPending
                      }
                    >
                      {activeStep !== maxSteps - 1 ? 'Next' : 'Create'}
                    </Button>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={handleCloseDrawer}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                }
              >
                <Typography fontWeight={700} fontSize={22}>
                  Elastio Deployment ({activeStep + 1}/{maxSteps})
                </Typography>
              </ListItem>
            </List>
            <Stack
              spacing={2}
              sx={{
                paddingX: 2.5,
                paddingBottom: 2.5,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  padding: 0,
                }}
              >
                <Box>
                  {activeStep === 0 && (
                    <AwsAccount
                      previousEmittedAccountId={previousEmittedAccountId}
                      setPreviousEmittedAccountId={setPreviousEmittedAccountId}
                      onAccountIdChange={onAccountIdChange}
                      listSupportedAwsRegionsData={listSupportedAwsRegionsData}
                      disabledVpc={disabledVpc}
                      isFinishedAccountLevelCFL={isFinishedAccountLevelCFL}
                      shouldInstallCfn={shouldInstallCfn}
                    />
                  )}
                  {activeStep === 1 && maxSteps === 2 && <AwsAccountElastio />}
                  {/*{activeStep === 2 && <Regions />}*/}
                  {/* <AwsAccount onAccountIdChange={onAccountIdChange} /> */}
                  {/* <AwsAccountElastio cfn={cfn} /> */}
                  {/* <Regions /> */}
                </Box>
              </Box>
            </Stack>
          </form>
        </FormProvider>
      </DrawerContent>
    </Drawer>
  )
}

export default AwsDrawer
