import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from '@mui/material'
import DataScanneIcon from 'ui-v2/src/assets/icons/data-scanned-icon'

import { formatBytes } from './util'
import { useAuth0 } from '@auth0/auth0-react'
import { useToast } from 'ui-v2/src/hooks/toast'

const DataScanned = () => {
  const [scannedBytes, setScannedBytes] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const toast = useToast()

  const { getIdTokenClaims } = useAuth0()

  const fetchDataScannedData = async () => {
    setIsLoading(true)
    try {
      const tokenClaims = await getIdTokenClaims()

      const path = '/api/v1/data-scanned-30d'

      const defaults = {
        hostname: '',
        authorization: `${tokenClaims?.__raw}`,
      }

      let fileConfig = {}
      try {
        fileConfig = require('../../../../../api-config.json')
      } catch (e) {
        /* use defaults if file not found */
      }

      const config: {
        hostname: string
        authorization: string
        authority?: string
      } = {
        ...defaults,
        ...fileConfig,
      }

      const headers: Record<string, string> = {
        authorization: config.authorization,
      }

      if (config.authority) {
        headers.authority = config.authority
      }

      const response = await fetch(`${config.hostname}${path}`, {
        method: 'GET',
        headers,
        mode: 'cors',
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      setIsLoading(false)
      setScannedBytes(data?.bytesScanned ?? 0)
    } catch (err: any) {
      setIsLoading(false)
      toast.error(
        err?.message ?? 'Something went wrong while fetching data scanned'
      )
    }
  }

  useEffect(() => {
    fetchDataScannedData()
  }, [getIdTokenClaims])

  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        background:
          theme.palette.mode === 'dark'
            ? 'rgba(246, 249, 252, 0.05)'
            : '#ffffff',
        boxShadow:
          theme.palette.mode === 'dark'
            ? 'none'
            : '0px 16px 20px 0px #A7ACD32B',
      })}
      className="assets-radar-chart-card"
    >
      <List
        sx={{
          padding: 0,
          marginBottom: 1.5,
        }}
      >
        <ListItem disableGutters sx={{ padding: 0 }}>
          <ListItemAvatar sx={{ minWidth: 50 }}>
            <Avatar
              sx={(theme) => ({
                backgroundColor:
                  theme.palette.mode === 'dark' ? '#727883' : '#85888c',
                color:
                  theme.palette.mode === 'dark'
                    ? 'rgba(211, 216, 222, 0.7)'
                    : '#f6f6f6',
              })}
            >
              <DataScanneIcon />
            </Avatar>
          </ListItemAvatar>
          <Typography
            fontSize={'22px'}
            fontWeight={700}
            sx={(theme) => ({
              background:
                theme.palette.mode === 'dark'
                  ? 'linear-gradient(180deg, rgba(168, 233, 129, 0.6) 0%, rgba(50, 182, 243, 0.6) 100%)'
                  : 'linear-gradient(180deg, rgba(168, 233, 129, 0.6) 0%, rgba(13, 13, 13, 0.6) 100%)',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              color: 'transparent',
            })}
          >
            {!isLoading ? formatBytes(scannedBytes) : null}
          </Typography>
        </ListItem>
      </List>
      <Typography
        mb={0.5}
        fontWeight={600}
        variant="body1"
        color="text.secondary"
      >
        Data Scanned
      </Typography>
      {/* TODO: Remove later if not needed */}
      {/* <Typography fontSize={11} sx={{ color: 'rgba(11, 151, 216, 1)' }}>
        +11% from 7 days ago
      </Typography> */}
    </Card>
  )
}

export default DataScanned
