/* eslint-disable import/no-extraneous-dependencies */
import { useMemo } from 'react'
import { Stack, FormControl, Typography } from '@mui/material'

import Grid from '@mui/material/Grid2'

import { accountRegionPairsList } from 'ui-v2/src/lib/helpers/data.helper'

import { Controller, useFormContext } from 'react-hook-form'
import {
  AssetVariants,
  PolicyEc2EbsTableRow,
  PolicyEfsTableRow,
  PolicyFormData,
  PolicyS3BucketTableRow,
} from 'ui-v2/src/lib/models/settings/policies/policies'
import FilterMultiSelect from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import RadioGroup from 'ui-v2/src/components/ui/inputs/radio-group'
import AssetEc2Ebs from './ec2-ebs'

import AssetsS3 from './s3-bucket'
import AssetsEfs from './efs'
import { useRedStackPolicyQuery } from 'ui-v2/src/hooks/queries/red-stack/red-stack-policy'

import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { ListAssetsForPolicyCoverage } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/index'
import FilterHelper from 'ui-v2/src/lib/helpers/filter.helper'
import {
  defaultSelectedFilters,
  defaultSelectedFiltersForS3Buckets,
} from 'ui-v2/src/lib/constants/filter-names.constant'
import { AssetKind } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { assetTypeVariant } from 'ui-v2/src/lib/helpers/policies.helper'
import { EMPTY_SCAN_WITH_TIMESTAMPS } from 'ui-v2/src/lib/constants/time.constant'
import {
  IntegrityCheckOptions,
  ProtectNewImmediately,
} from 'ui-v2/src/lib/constants/policies.constant'
import { SnapshotImport } from 'ui-v2/src/lib/grpc'

const Assets = () => {
  const { control, setValue, watch, getValues } =
    useFormContext<PolicyFormData>()
  const { data: redStackListData } = useRedStackPolicyQuery()
  const allActiveRedStacks = redStackListData?.filter((v) => v.isActive) ?? []
  const possibleCloudConnectors = accountRegionPairsList(allActiveRedStacks)
  const possibleCloudConnectorsOptions = possibleCloudConnectors.map(
    (option) => ({
      label: option.label as string,
      value: option.value?.toString() as string,
    })
  )

  const tabAssetTypeSelectedNum = watch('tabAssetTypeSelectedNum')

  const shouldRenderEc2EbsProtectionOptions =
    tabAssetTypeSelectedNum === AssetVariants.EBS

  const shouldRenderS3BucketProtectionOptions =
    tabAssetTypeSelectedNum === AssetVariants.S3

  const shouldRenderEfsProtectionOptions =
    tabAssetTypeSelectedNum === AssetVariants.EFS

  const { data: liveAssetsData, isLoading: isAssetsLiveDataLoading } =
    useAssetsLiveQuery()

  const getAssetsList = (filters: any) => {
    if (!liveAssetsData) {
      return
    }
    const assetsLiveData = {
      instances: liveAssetsData.ec2Instances,
      volumes: liveAssetsData.ebsVolumes,
      s3Buckets: liveAssetsData.s3Buckets,
      efs: liveAssetsData.efs,
      lastBackups: liveAssetsData.lastElastioBackupsMap,
    }
    return new ListAssetsForPolicyCoverage(assetsLiveData).execute(
      FilterHelper.getAssetsDataForPolicyCoverageFilter(filters, '')
    )
  }

  const assetsListWithFilters = useMemo(() => {
    return getAssetsList(defaultSelectedFilters())
  }, [liveAssetsData])

  const assetsListWithS3Filters = useMemo(() => {
    return getAssetsList(defaultSelectedFiltersForS3Buckets())
  }, [liveAssetsData])

  const assetsListWithEfsFilters = useMemo(() => {
    return getAssetsList(defaultSelectedFiltersForS3Buckets())
  }, [liveAssetsData])

  const ec2Assets = useMemo(() => {
    if (!assetsListWithFilters) {
      return
    }
    return assetsListWithFilters.filter(
      (item) => item.type === AssetKind.AWS_EC2
    )
  }, [assetsListWithFilters])

  const ebsAssets = useMemo(() => {
    if (!assetsListWithFilters) {
      return
    }
    return assetsListWithFilters.filter(
      (item) => item.type === AssetKind.AWS_EBS
    )
  }, [assetsListWithFilters])

  const s3Buckets = useMemo(() => {
    if (!assetsListWithS3Filters) {
      return
    }
    return assetsListWithS3Filters.filter(
      (item) => item.type === AssetKind.AWS_S3
    )
  }, [assetsListWithS3Filters])

  const efsAssets = useMemo(() => {
    if (!assetsListWithS3Filters) {
      return
    }
    return assetsListWithS3Filters.filter(
      (item) => item.type === AssetKind.AWS_EFS
    )
  }, [assetsListWithEfsFilters])

  const handlEc2EbsAllSelect = () => {
    setValue('selectedAssets', [...(ec2Assets ?? []), ...(ebsAssets ?? [])])
  }

  const handleEc2EbsAssetsSelect = (rows: Array<PolicyEc2EbsTableRow>) => {
    const assetIds = rows.map((row) => row.id)

    const selectedAssets = assetsListWithFilters?.filter((item) =>
      assetIds.includes(item.asset?.id)
    )
    setValue('selectedEc2EbsAssetsRows', rows)
    setValue('selectedAssets', selectedAssets ?? [])
  }

  const handleS3BucketAssetsSelect = (rows: Array<PolicyS3BucketTableRow>) => {
    const assetIds = rows.map((row) => row.id)

    const selectedAssets = s3Buckets?.filter((item) =>
      assetIds.includes(item.asset?.id)
    )
    setValue('selectedS3BucketAssetsRows', rows)
    setValue('selectedS3Assets', selectedAssets ?? [])
  }

  const handleEfsAssetsSelect = (rows: Array<PolicyEfsTableRow>) => {
    const assetIds = rows.map((row) => row.id)

    const selectedAssets = efsAssets?.filter((item) =>
      assetIds.includes(item.asset?.id)
    )
    setValue('selectedEfsAssetsRows', rows)
    setValue('selectedEfsAssets', selectedAssets ?? [])
  }

  const handleDefaultAssetvalues = () => {
    setValue('integrityScan', {
      scanForMalware: true,
      scanForRansomware: true,
    })
    setValue('isEntropyDetectionEnabled', false)
    setValue(
      'integrityCheck',
      IntegrityCheckOptions.INTEGRITY_CHECK_FILE_SYSTEM
    )
    setValue('protectNewImmediately', ProtectNewImmediately.DISABLED)

    setValue('scanWithTimestamps', EMPTY_SCAN_WITH_TIMESTAMPS())
    setValue('policyTags', [])

    setValue('selectedEc2EbsAssetsRows', [])
    setValue('selectedS3BucketAssetsRows', [])
    setValue('selectedEfsAssetsRows', [])
    setValue('selectedAssets', [])
    setValue('selectedS3Assets', [])
    setValue('selectedEfsAssets', [])

    setValue('snapshotImportVariant', SnapshotImport.SKIP)
    setValue('skipEbsBackup', false)
  }

  return (
    <Stack maxWidth={1120}>
      <Typography
        mb={3}
        variant="body1"
        fontWeight={400}
        color="text.secondary"
      >
        Choose Assets To Protect.
      </Typography>
      <Grid container spacing={2}>
        <Grid size={12}>
          <FormControl fullWidth size="small" sx={{ maxWidth: 258 }}>
            <Typography variant="body2" mb={1}>
              Choose Scope
            </Typography>
            <Controller
              control={control}
              name="selectedCloudConnectors"
              render={({ field }) => (
                <FilterMultiSelect
                  {...field}
                  size="small"
                  options={possibleCloudConnectorsOptions}
                  value={field.value.map(
                    (option) => option.value?.toString() as string
                  )}
                  onChange={(options) => {
                    const selectedOptions = possibleCloudConnectors.filter(
                      (option) =>
                        options.includes(option.value?.toString() as string)
                    )

                    field.onChange(selectedOptions)
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid size={12}>
          <Controller
            control={control}
            name="tabAssetTypeSelectedNum"
            rules={{
              validate: (value) => {
                const selectedS3BucketAssetsRows = getValues(
                  'selectedS3BucketAssetsRows'
                )
                const selectedEfsAssetsRows = getValues('selectedEfsAssetsRows')

                if (
                  value === AssetVariants.S3 &&
                  s3Buckets?.length &&
                  selectedS3BucketAssetsRows.length === 0
                ) {
                  return 'Please select at least one S3 Bucket'
                } else if (
                  value === AssetVariants.EFS &&
                  efsAssets?.length &&
                  selectedEfsAssetsRows.length === 0
                ) {
                  return 'Please select at least one EFS'
                }
              },
            }}
            render={({ field, fieldState }) => (
              <RadioGroup
                {...field}
                row
                value={field.value}
                options={[
                  {
                    label: 'EC2/EBS',
                    value: AssetVariants.EBS,
                  },
                  {
                    label: 'S3 Bucket',
                    value: AssetVariants.S3,
                  },
                  {
                    label: 'EFS',
                    value: AssetVariants.EFS,
                  },
                ]}
                onChange={(e) => {
                  field.onChange(+e.target.value)
                  setValue('selectedVariant', assetTypeVariant(+e.target.value))

                  handleDefaultAssetvalues()
                }}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
          {shouldRenderEc2EbsProtectionOptions && (
            <AssetEc2Ebs
              liveAssetsData={liveAssetsData}
              ec2Assets={ec2Assets}
              ebsAssets={ebsAssets}
              isLoading={isAssetsLiveDataLoading}
              handlEc2EbsAllSelect={handlEc2EbsAllSelect}
              handleAssetsSelect={handleEc2EbsAssetsSelect}
            />
          )}
          {shouldRenderS3BucketProtectionOptions && (
            <AssetsS3
              s3Buckets={s3Buckets}
              isLoading={isAssetsLiveDataLoading}
              handleAssetsSelect={handleS3BucketAssetsSelect}
            />
          )}
          {shouldRenderEfsProtectionOptions && (
            <AssetsEfs
              efsAssets={efsAssets}
              isLoading={isAssetsLiveDataLoading}
              handleAssetsSelect={handleEfsAssetsSelect}
            />
          )}
        </Grid>
      </Grid>
    </Stack>
  )
}

export default Assets
