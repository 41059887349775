import React, { useMemo } from 'react'
import {
  Box,
  Typography,
  Divider,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  circularProgressClasses,
} from '@mui/material'
import { useCloudConnectorJobQuery } from 'ui-v2/src/hooks/queries/jobs'
import { useRedStackListQuery } from 'ui-v2/src/hooks/queries/red-stack'
import RedStackModel from 'ui-v2/src/lib/models/base-model/red-stack.model'
import { getCloudConnectorJobStatusMessage } from 'ui-v2/src/lib/transformers/cloud-connector-job/cloud-connector-job'
import dayjs from 'dayjs'

interface CollapsibleDetailsDrawerProps {
  monitorId: string
}

const CollapsibleDetailsDrawer = ({
  monitorId,
}: CollapsibleDetailsDrawerProps) => {
  const { data: monitorDetailsData, isLoading: isMonitorDetailsLoading } =
    useCloudConnectorJobQuery(monitorId)

  const { data: redStackListQuery = [] } = useRedStackListQuery()

  const redStackListData = useMemo(
    () =>
      redStackListQuery.map(
        (data) =>
          new RedStackModel({
            ...data,
            awsAccount: data.awsAccountId,
            lastIntegrityCheckErrorsList: data.lastIntegrityCheckErrors,
          })
      ),
    [redStackListQuery]
  )

  const redstack = useMemo(() => {
    if (!redStackListData.length || !monitorDetailsData?.ccId) {
      return undefined
    }

    return redStackListData.find(
      (r) => String(r.redStackId) === monitorDetailsData.ccId
    )
  }, [redStackListData, monitorDetailsData?.ccId])

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#141218' : '#f7f9fc',
        paddingLeft: '68px',
      })}
    >
      {isMonitorDetailsLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          p={4}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={(theme) => ({
            borderLeft:
              theme.palette.mode === 'dark'
                ? '1px solid rgba(204, 204, 220, 0.2)'
                : '1px solid rgba(204, 204, 220, 0.85)',
            paddingLeft: '26px',
          })}
        >
          <Box display={'flex'} flexWrap={'wrap'} columnGap={6} paddingY={1}>
            <Typography
              gap={0.5}
              mb={0.5}
              display={'flex'}
              alignItems={'center'}
              variant="body2"
              fontWeight={400}
            >
              <Typography variant="body2" fontWeight={400}>
                Account:
              </Typography>
              {redstack?.awsAccount ?? ''}
            </Typography>
            <Typography
              gap={0.5}
              mb={0.5}
              display={'flex'}
              alignItems={'center'}
              variant="body2"
              fontWeight={400}
            >
              <Typography variant="body2" fontWeight={400}>
                Region:
              </Typography>
              {redstack?.awsRegion ?? ''}
            </Typography>
          </Box>
          <Divider />
          {!!monitorDetailsData?.childrenList &&
            monitorDetailsData?.childrenList?.length > 0 &&
            monitorDetailsData?.childrenList?.map((childJob) => (
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        width="80px"
                        sx={{
                          backgroundColor: 'transparent !important',
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                      >
                        <Box display={'flex'} sx={{ position: 'relative' }}>
                          <CircularProgress
                            variant="determinate"
                            sx={{ color: '#4E5665' }}
                            size={20}
                            thickness={8}
                            value={100}
                          />
                          <CircularProgress
                            variant="determinate"
                            sx={{
                              color: '#257C38',
                              position: 'absolute',
                              left: 0,
                              [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                              },
                            }}
                            size={20}
                            thickness={8}
                            value={childJob.progress ?? 0}
                          />
                          <Typography marginLeft={1} variant="body2">
                            {childJob?.isFinished
                              ? dayjs(childJob?.finishedAt).format(
                                  'MM/DD/YY hh:mm A'
                                )
                              : dayjs(childJob?.startedAt).format(
                                  'MM/DD/YY hh:mm A'
                                )}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        width="150px"
                        sx={{
                          backgroundColor: 'transparent !important',
                          textAlign: 'left',
                        }}
                      >
                        {childJob?.name.split(',')[0]}
                      </TableCell>
                      <TableCell
                        width="150"
                        sx={{
                          backgroundColor: 'transparent !important',
                          textAlign: 'left',
                        }}
                      >
                        {getCloudConnectorJobStatusMessage(
                          childJob?.statusMessage,
                          childJob?.status
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ))}
        </Box>
      )}
    </Box>
  )
}

export default CollapsibleDetailsDrawer
