import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
} from 'ui-v2/src/components/ui/drawer'
import {
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useGetWebhookQuery } from 'ui-v2/src/hooks/queries/webhooks'
import { WEBHOOK_MAP } from './data'
import { MultiSelectOption } from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import WebhookModel, {
  ApiKey,
  BasicAuth,
  BearerToken,
  EventList,
  Secret,
  Severity,
  VersionType,
  WebhookAuthentication,
  WebhookAuthType,
  WebhookEventSubscription,
  WebhookFormData,
} from 'ui-v2/src/lib/models/webhooks'
import { useCreateWebhookMutation } from 'ui-v2/src/hooks/queries/webhooks/create-webhook'
import { useUpdateWebhookMutation } from 'ui-v2/src/hooks/queries/webhooks/update-webhook'
import WebhookForm from './webhook-form'
import {
  mapSeverityToEnum,
  setWebhookAuthType,
  webhookFormDefaultValue,
} from './utils'

const AddWebhookDrawer = () => {
  const { action, id } = useParams()
  const navigate = useNavigate()

  const [authType, setAuthType] = useState<WebhookAuthType>('none')
  const [versionType, setVersionType] = useState<VersionType>('v0')
  const [eventType, setEventType] = useState<string>('account-level-stack')
  const [eventList, setEventList] = useState<Array<EventList>>([])
  const [isAddDisabled, setIsAddDisabled] = useState(true)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const { data: webhookData } = useGetWebhookQuery(id as string)
  const { mutate: createWebhookMutate } = useCreateWebhookMutation()
  const { mutate: updateWebhookMutate } = useUpdateWebhookMutation()
  const [searchParams, setSearchParams] = useSearchParams()
  const severityList = searchParams.get(WEBHOOK_MAP.SEVERITY)?.split(',') || []

  const handleSeverityChange = (
    newSeverityList: Array<MultiSelectOption['value']>
  ) => {
    if (newSeverityList.length === 0) {
      setIsAddDisabled(true)
      searchParams.delete(WEBHOOK_MAP.SEVERITY)
    } else {
      searchParams.set(WEBHOOK_MAP.SEVERITY, newSeverityList.join(','))
      setIsAddDisabled(false)
    }
    setSearchParams(new URLSearchParams(searchParams))
  }

  const handleAddEvent = () => {
    setEventList((prevList) => [
      ...prevList,
      {
        versionType,
        severityList,
        eventType: eventType,
      },
    ])
    setEventType('')
    setSearchParams([])
    setIsAddDisabled(true)
  }

  const handleDeleteEvent = (index: number) => {
    const updatedList = eventList.filter((_, i) => i !== index)
    setEventList(updatedList)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<WebhookFormData>({
    defaultValues: webhookFormDefaultValue,
  })

  const closeDialog = () => {
    reset()
    eventList.length = 0
    setIsSubmitDisabled(true)
    setAuthType('none')
    navigate(`/settings/${SETTINGS_ROUTE_SEGMENTS.WEBHOOKS}`)
  }

  const onSubmit = (data: WebhookFormData) => {
    const formData = { ...data }

    switch (authType) {
      case 'none':
        delete formData.authentication.api_key
        delete formData.authentication.basic_auth
        delete formData.authentication.bearer_token
        break
      case 'basic_authorization':
        delete formData.authentication.bearer_token
        delete formData.authentication.api_key
        break
      case 'bearer':
        delete formData.authentication.basic_auth
        delete formData.authentication.api_key
        break
      case 'api_key':
        delete formData.authentication.basic_auth
        delete formData.authentication.bearer_token
        break
    }

    let authentication: BasicAuth | ApiKey | BearerToken | undefined = undefined

    const { authentication: authenticationFormData } = formData

    if (authenticationFormData.api_key) {
      const keySecret = new Secret({
        secret: authenticationFormData.api_key.key.value,
        encrypted: false,
      })
      const valueSecret = new Secret({
        secret: authenticationFormData.api_key.value,
        encrypted: false,
      })
      authentication = new ApiKey(keySecret, valueSecret)
    } else if (authenticationFormData.basic_auth) {
      const loginSecret = new Secret({
        secret: authenticationFormData.basic_auth.login.value,
        encrypted: false,
      })
      const passwordSecret = new Secret({
        secret: authenticationFormData.basic_auth.password.value,
        encrypted: false,
      })
      authentication = new BasicAuth(loginSecret, passwordSecret)
    } else if (authenticationFormData.bearer_token) {
      const tokenSecret = new Secret({
        secret: authenticationFormData.bearer_token.token.value,
        encrypted: false,
      })
      authentication = new BearerToken(tokenSecret)
    }

    const events: Array<WebhookEventSubscription> = eventList.map((item) => {
      const severities = item.severityList
        .map((severity) => mapSeverityToEnum(severity))
        .filter((severity) => severity !== undefined)
      return new WebhookEventSubscription(
        item.eventType,
        item.versionType,
        severities
      )
    })

    if (action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.ADD_WEBHOOK) {
      createWebhookMutate(
        new WebhookModel({
          id: String(Date.now()),
          name: formData.name,
          endpoint: formData.endpoint,
          authentication: new WebhookAuthentication(authentication),
          description: formData.description ?? '',
          event_subscriptions: events,
        })
      )
    } else {
      updateWebhookMutate(
        new WebhookModel({
          id: String(id),
          name: formData.name,
          endpoint: formData.endpoint,
          authentication: new WebhookAuthentication(authentication),
          description: formData.description ?? '',
          event_subscriptions: events,
        })
      )
    }

    closeDialog()
    reset()
  }

  useEffect(() => {
    if (webhookData) {
      const endpoint = webhookData.getEndpoint()
      const updatedEndpoint = endpoint.replace(/^https?:\/\//, '')

      const webhookAuthentication = webhookData.getAuthentication()

      setWebhookAuthType(webhookAuthentication, setAuthType, setValue)

      const eventSubscriptions = webhookData.getEventSubscriptions()

      const updatedEventSubscriptions = eventSubscriptions.map(
        (subscription) => ({
          eventType: subscription.getEventType() || '',
          versionType: subscription.getVersion() || 'v0',
          severityList: subscription.getSeverities()
            ? subscription.getSeverities().map((severity) => Severity[severity])
            : [],
        })
      )

      setValue('name', webhookData.getName())
      setValue('endpoint', updatedEndpoint)
      setValue('description', webhookData.getDescription())
      setEventList(updatedEventSubscriptions)
    }
  }, [webhookData, setValue])

  useEffect(() => {
    setIsSubmitDisabled(eventList.length === 0)
  }, [eventList])

  return (
    <Drawer
      open={
        action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.ADD_WEBHOOK ||
        action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.EDIT_WEBHOOK
      }
      onClose={closeDialog}
    >
      <DrawerHeader
        title={
          action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.ADD_WEBHOOK
            ? 'Add Webhook'
            : 'Edit Webhook'
        }
        handleCloseDrawer={closeDialog}
      />
      <DrawerContent isEmpty={false}>
        <WebhookForm
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          register={register}
          errors={errors}
          setValue={setValue}
          setAuthType={setAuthType}
          authType={authType}
          eventList={eventList}
          handleSeverityChange={handleSeverityChange}
          handleAddEvent={handleAddEvent}
          handleDeleteEvent={handleDeleteEvent}
          eventType={eventType}
          setEventType={setEventType}
          versionType={versionType}
          setVersionType={setVersionType}
          action={action}
          isSubmitDisabled={isSubmitDisabled}
          severityList={severityList}
          isAddDisabled={isAddDisabled}
        />
      </DrawerContent>
    </Drawer>
  )
}

export default AddWebhookDrawer
