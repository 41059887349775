import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { RexClient } from 'ui-v2/src/lib/clients'
import { useQuery } from '@tanstack/react-query'

export function useGetCloudInformationLinkQuery(
  awsAccountId: string | undefined
) {
  const rexClient = useGrpcClient(RexClient)

  return useQuery({
    queryKey: ['rex', 'get-cloud-information-link'],
    queryFn: () => {
      if (!rexClient) {
        throw new Error('Rex client not initialized')
      }
      if (!awsAccountId) {
        return undefined
      }

      return rexClient.getCloudFormationLink(awsAccountId)
    },
    enabled: !!rexClient,
  })
}
