import React, { useMemo } from 'react'
import {
  Avatar,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from '@mui/material'
import FileScanneIcon from 'ui-v2/src/assets/icons/file-scanned-icon'
import { useAwsFileScansQuery } from 'ui-v2/src/hooks/queries/aws-stats'
import { useOvaFilesScannedQuery } from 'ui-v2/src/hooks/queries/ova-statistics'

const FilesScanned = () => {
  const { data: awsFilesScannedData, isLoading: isAwsFilesScannedDataLoading } =
    useAwsFileScansQuery({})

  const { data: ovaFilesScannedData, isLoading: isOvaFilesScannedDataLoading } =
    useOvaFilesScannedQuery({})

  const filesScanned = useMemo(() => {
    if (!awsFilesScannedData || !ovaFilesScannedData) {
      return undefined
    }

    const awsFilesScanned =
      awsFilesScannedData.cleanFiles + awsFilesScannedData.infectedFiles

    const ovaFilesScanned =
      ovaFilesScannedData.cleanFilesCount +
      ovaFilesScannedData.infectedFilesCount

    return awsFilesScanned + ovaFilesScanned
  }, [awsFilesScannedData, ovaFilesScannedData])

  const isLoading = isAwsFilesScannedDataLoading || isOvaFilesScannedDataLoading

  const formatter = new Intl.NumberFormat('en', {
    notation: 'compact',
    maximumFractionDigits: 1,
    compactDisplay: 'short',
  })

  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        background:
          theme.palette.mode === 'dark'
            ? 'rgba(246, 249, 252, 0.05)'
            : '#ffffff',
        boxShadow:
          theme.palette.mode === 'dark'
            ? 'none'
            : '0px 16px 20px 0px #A7ACD32B',
      })}
      className="assets-radar-chart-card"
    >
      <List
        sx={{
          padding: 0,
          marginBottom: 1.5,
        }}
      >
        <ListItem disableGutters sx={{ padding: 0 }}>
          <ListItemAvatar sx={{ minWidth: 50 }}>
            <Avatar
              sx={(theme) => ({
                backgroundColor:
                  theme.palette.mode === 'dark' ? '#727883' : '#85888c',
                color:
                  theme.palette.mode === 'dark'
                    ? 'rgba(211, 216, 222, 0.7)'
                    : '#f6f6f6',
              })}
            >
              <FileScanneIcon />
            </Avatar>
          </ListItemAvatar>
          <Typography
            fontSize={'22px'}
            fontWeight={700}
            sx={(theme) => ({
              background:
                theme.palette.mode === 'dark'
                  ? 'linear-gradient(180deg, rgba(168, 233, 129, 0.6) 0%, rgba(50, 182, 243, 0.6) 100%)'
                  : 'linear-gradient(180deg, rgba(168, 233, 129, 0.6) 0%, rgba(13, 13, 13, 0.6) 100%)',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              color: 'transparent',
            })}
          >
            {!isLoading ? formatter.format(filesScanned ?? 0) : null}
          </Typography>
        </ListItem>
      </List>
      <Typography
        mb={0.5}
        fontWeight={600}
        variant="body1"
        color="text.secondary"
      >
        Files Scanned
      </Typography>
      {/* TODO: remove if not needed later */}
      {/* <Typography fontSize={11} sx={{ color: 'rgba(11, 151, 216, 1)' }}>
        +8% from 7 days ago
      </Typography> */}
    </Card>
  )
}

export default FilesScanned
