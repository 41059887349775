/* eslint-disable import/no-extraneous-dependencies */
import { AssetAccount } from 'blues-corejs/dist'

export enum AccountStatusConstant {
  UNDEFINED = -1,
  CONNECTED,
  DISCONNECTED,
  MIXED,
  UPGRADE_REQUIRED,
}

export interface ActiveDeploymentTableData {
  id: string
  awsAccount: string
  accountAlias: string
  awsRegions: Array<string>
  updatedAt: Date
  assetsAccount: Array<AssetAccount> | undefined
  statuses: Array<AccountStatusConstant>
  status: AccountStatusConstant
  messages: Array<string>
  redStackIds: Array<string>
}
