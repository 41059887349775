import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Drawer, IconButton, List, ListItem, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { RoiData } from 'ui-v2/src/views/dashboard/dashboard/return-investment-card'
import { industryOptions } from './industry-options'
import RoiForm from './roi-form'

type ReturnOfInvestmentDrawerProps = {
  isOpen: boolean
  closeDrawer: () => void
  dollarFormatter: Intl.NumberFormat
  storedRoiData: RoiData
}

const roiReductionFactor = 0.01

const getStoredRoiData = (): RoiData => {
  const storedData = localStorage.getItem('roiData')
  if (storedData) {
    return JSON.parse(storedData)
  }
  return {
    selectedIndustry: 'it',
    revenueInput: null,
    downtimeCost: null,
    calculatedRoi: '0',
  }
}

const ReturnOfInvestmentDrawer: React.FC<ReturnOfInvestmentDrawerProps> = ({
  isOpen,
  closeDrawer,
  dollarFormatter,
  storedRoiData,
}) => {
  const [localRoiData, setLocalRoiData] = useState<RoiData>(storedRoiData)
  const [isFormCleared, setIsFormCleared] = useState(false)

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<RoiData>({
    defaultValues: localRoiData,
    mode: 'onChange',
  })

  const { selectedIndustry, revenueInput, downtimeCost } = watch()

  const handleClearClick = () => {
    setValue('selectedIndustry', 'it')
    setValue('revenueInput', null)
    setValue('downtimeCost', null)
    setIsFormCleared(true)
  }

  const onSubmit = (data: RoiData) => {
    const selectedIndustryData = industryOptions.find(
      (industry) => industry.value === data.selectedIndustry
    )

    const hourlyDowntimeCost =
      (data.revenueInput || 0) > 1000000
        ? selectedIndustryData?.enterprise
        : selectedIndustryData?.smb

    const downtimeSavings =
      Number(hourlyDowntimeCost) * (data.downtimeCost || 0)

    const roiData: RoiData = {
      selectedIndustry: data.selectedIndustry,
      revenueInput: data.revenueInput,
      downtimeCost: data.downtimeCost,
      calculatedRoi: downtimeSavings.toString(),
    }

    // Reduce ROI data with the reduction factor
    const calculatedRoi = Number(roiData.calculatedRoi) * roiReductionFactor
    roiData.calculatedRoi = calculatedRoi.toString()

    localStorage.setItem('roiData', JSON.stringify(roiData))
    closeDrawer()
  }

  useEffect(() => {
    if (isOpen) {
      const data = getStoredRoiData()
      setLocalRoiData(data)
      // Update form values when ROI data changes
      setValue('selectedIndustry', data.selectedIndustry)
      setValue('revenueInput', data.revenueInput)
      setValue('downtimeCost', data.downtimeCost)
      setIsFormCleared(false)
    }
  }, [storedRoiData, isOpen, setValue])

  useEffect(() => {
    if (isFormCleared && (selectedIndustry || revenueInput || downtimeCost)) {
      setIsFormCleared(false)
    }
  }, [selectedIndustry, revenueInput, downtimeCost, isFormCleared])

  const isSaveDisabled =
    isFormCleared ||
    !isValid ||
    !selectedIndustry ||
    !revenueInput ||
    !downtimeCost

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      sx={{ backdropFilter: 'blur(3px)' }}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '400px',
        },
      }}
    >
      <List
        sx={{
          paddingX: 0,
          borderBottom: '2px solid rgba(51, 51, 51, 1)',
          backgroundColor: 'rgba(25, 35, 47, 0.2)',
        }}
      >
        <ListItem
          sx={{ paddingX: 2.5 }}
          secondaryAction={
            <IconButton edge="end" aria-label="delete" onClick={closeDrawer}>
              <Close />
            </IconButton>
          }
        >
          <Typography fontWeight={700} fontSize={22}>
            ROI Calculation
          </Typography>
        </ListItem>
      </List>
      <RoiForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        storedRoiData={storedRoiData}
        dollarFormatter={dollarFormatter}
        control={control}
        handleClearClick={handleClearClick}
        isSaveDisabled={isSaveDisabled}
      />
    </Drawer>
  )
}

export default ReturnOfInvestmentDrawer
