import React from 'react'
import { useNavigate } from 'react-router-dom'
import ActionMenu from 'ui-v2/src/components/ui/data-table/action-menu'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { ActiveDeploymentTableData } from '../types'
import { useDeploymentStore } from '../store/deployment-store'

const linkProtectedLink = `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.LINK_PROTECTED_ACCOUNT}`
const unlinkProtectedLink = `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.UNLINK_CLOUD_CONNECTOR}`

const MenuItemsConnector = ({ row }: { row: ActiveDeploymentTableData }) => {
  const navigate = useNavigate()
  const { awsAccount } = row
  const { setRowData } = useDeploymentStore()

  const menuItems = [
    {
      name: 'Link Protected Account',
      action: () => {
        navigate(`${linkProtectedLink}/${awsAccount}`)
      },
    },
    {
      name: 'Unlink',
      action: () => {
        navigate(`${unlinkProtectedLink}`)
        setRowData(row)
      },
    },
    {
      name: 'Modify',
      action: () => console.log('Modify'),
    },
    {
      name: 'Upgrade',
      action: () => {
        console.log('upgrade')
      },
    },
  ]

  return <ActionMenu menuItems={menuItems} />
}

export default MenuItemsConnector
