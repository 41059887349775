/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAssetsVulnerabilitiesFilters } from 'ui-v2/src/hooks/filters/assets-vulnerabilities'
import {
  ASSETS_VULNERABILITIES_FILTERS_MAP,
  SEARCH_PARAM,
} from 'ui-v2/src/lib/constants/assets.constant'
import {
  FilterOptions,
  getRiskNameFormQuery,
  initialPrioritySortForEBSSnapshotsTable,
  useLiveAssetsDataForAssetsVulnerabilities,
} from '../utils'
import { Stack } from '@mui/material'
import { getVolumesVulnerabilitiesColumns } from './column-config'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import { ValueInterface } from 'ui-v2/src/lib/engine-types'
import DataHelper from 'ui-v2/src/lib/helpers/data.helper'
import {
  GetSnapshotsVulnerabilitiesFilters,
  ListSnapshotsVulnerabilities,
  SnapshotsVulnerabitiesFilters,
  VulnerabilitiesDetectionStrategy,
} from 'blues-corejs/dist'
import { getSnapshotVulnerabilitiesPossibleFilters } from '../get-possible-filters'
import AssetsVulnerabilitiesFilters from '../filters'

const AssetsVulnerabilitiesSnapshots = () => {
  const { data: liveAssetsData, isLoading } =
    useLiveAssetsDataForAssetsVulnerabilities()
  const filters = useAssetsVulnerabilitiesFilters()
  const [searchParams] = useSearchParams()
  const search = searchParams.get(SEARCH_PARAM)

  const [queryParams, setQueryParams] = useState<{
    t?: string
    vulnerability?: string
    risk?: string
  }>({})
  const riskFromQuery = getRiskNameFormQuery(queryParams.risk)
  const vulnerabilitiesDetectionStrategy = useMemo(
    () => new VulnerabilitiesDetectionStrategy(),
    []
  )

  const data = useMemo(() => {
    if (!liveAssetsData) {
      return undefined
    }

    const { instances, volumes, snapshots, scans, lastBackups } = liveAssetsData

    if (instances.length || volumes.length) {
      return {
        instances,
        volumes,
        lastBackups,
        snapshots,
        scans,
      }
    }
  }, [liveAssetsData])

  const snapshotVulnerabilitiesPossibleFilters = useMemo(() => {
    if (!data) {
      return undefined
    }

    const vulnerabilitiesSnapshotFilters =
      new GetSnapshotsVulnerabilitiesFilters({
        snapshots: data.snapshots,
        riskFromQuery: riskFromQuery,
      }).execute(vulnerabilitiesDetectionStrategy)

    const possibleSnapshotsFilters = getSnapshotVulnerabilitiesPossibleFilters(
      vulnerabilitiesSnapshotFilters
    )

    const filterOptions: FilterOptions = possibleSnapshotsFilters.reduce(
      (acc, { name, options }) => ({
        ...acc,
        [name]:
          options
            ?.map(({ name: optionName }) => optionName)
            .filter((value): value is string => typeof value === 'string') ||
          [],
      }),
      {} as FilterOptions
    )

    return filterOptions
  }, [data])

  const volumesFilteredData = useMemo(() => {
    if (!data) {
      return undefined
    }

    const parseFilters = (selectedFilters: Array<ValueInterface>) => {
      return DataHelper.getDataForDashboardFilter(selectedFilters, search ?? '')
    }

    return initialPrioritySortForEBSSnapshotsTable(
      new ListSnapshotsVulnerabilities({
        snapshots: data.snapshots,
        volumes: data.volumes,
      }).execute(
        parseFilters(filters) as SnapshotsVulnerabitiesFilters,
        vulnerabilitiesDetectionStrategy
      )
    )
  }, [data, filters])

  const columns = useMemo(getVolumesVulnerabilitiesColumns, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    setQueryParams({
      t: urlParams.get('t') ?? undefined,
      vulnerability: urlParams.get('vulnerability') ?? undefined,
      risk: urlParams.get('risk') ?? undefined,
    })
  }, [window.location.search])

  return (
    <Stack gap={1}>
      <AssetsVulnerabilitiesFilters
        showSearch
        filters={[
          ASSETS_VULNERABILITIES_FILTERS_MAP.ACCOUNT_ID,
          ASSETS_VULNERABILITIES_FILTERS_MAP.REGION,
          ASSETS_VULNERABILITIES_FILTERS_MAP.MISCONFIGURATION,
          ASSETS_VULNERABILITIES_FILTERS_MAP.RISK,
        ]}
        filterValues={{
          [ASSETS_VULNERABILITIES_FILTERS_MAP.ACCOUNT_ID]:
            snapshotVulnerabilitiesPossibleFilters?.accountId,
          [ASSETS_VULNERABILITIES_FILTERS_MAP.REGION]:
            snapshotVulnerabilitiesPossibleFilters?.regions,
          [ASSETS_VULNERABILITIES_FILTERS_MAP.MISCONFIGURATION]:
            snapshotVulnerabilitiesPossibleFilters?.snapshotVulnerabilityTypes,
          [ASSETS_VULNERABILITIES_FILTERS_MAP.RISK]:
            snapshotVulnerabilitiesPossibleFilters?.riskType,
        }}
      />
      <ClientTable
        idField="volumeId"
        data={volumesFilteredData ?? []}
        columns={columns}
        isLoading={isLoading}
      />
    </Stack>
  )
}

export default AssetsVulnerabilitiesSnapshots
