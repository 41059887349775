import React, { useMemo } from 'react'
import {
  Avatar,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from '@mui/material'
import AssetsScanneIcon from 'ui-v2/src/assets/icons/assets-scanned-icon'
import { useAwsScanCoverageQuery } from 'ui-v2/src/hooks/queries/aws-stats'
import { useOvaAssetsScannedQuery } from 'ui-v2/src/hooks/queries/ova-statistics'

const AssetsScanned = () => {
  const { data: awsScanCoverageData, isLoading: isAwsScanCoverageDataLoading } =
    useAwsScanCoverageQuery({})
  const {
    data: ovaAssetsScannedData,
    isLoading: isOvaAssetsScannedDataLoading,
  } = useOvaAssetsScannedQuery({})

  const assetsScanned = useMemo(() => {
    if (!awsScanCoverageData || !ovaAssetsScannedData) {
      return undefined
    }

    const services = ['ebs', 'ec2', 'efs', 's3'] as const

    const awsCoveredCount = services.reduce(
      (sum, service) => sum + (awsScanCoverageData[service]?.covered ?? 0),
      0
    )

    const ovaCoveredAssets = ovaAssetsScannedData.totalAssets
    return awsCoveredCount + ovaCoveredAssets
  }, [awsScanCoverageData, ovaAssetsScannedData])

  const isLoading =
    isAwsScanCoverageDataLoading || isOvaAssetsScannedDataLoading

  const formatter = new Intl.NumberFormat('en', {
    notation: 'compact',
    maximumFractionDigits: 1,
    compactDisplay: 'short',
  })

  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        background:
          theme.palette.mode === 'dark'
            ? 'rgba(246, 249, 252, 0.05)'
            : '#ffffff',
        boxShadow:
          theme.palette.mode === 'dark'
            ? 'none'
            : '0px 16px 20px 0px #A7ACD32B',
      })}
      className="assets-radar-chart-card"
    >
      <List
        sx={{
          padding: 0,
          marginBottom: 1.5,
        }}
      >
        <ListItem disableGutters sx={{ padding: 0 }}>
          <ListItemAvatar sx={{ minWidth: 50 }}>
            <Avatar
              sx={(theme) => ({
                backgroundColor:
                  theme.palette.mode === 'dark' ? '#727883' : '#85888c',
                color:
                  theme.palette.mode === 'dark'
                    ? 'rgba(211, 216, 222, 0.7)'
                    : '#f6f6f6',
              })}
            >
              <AssetsScanneIcon />
            </Avatar>
          </ListItemAvatar>
          <Typography
            fontSize={'22px'}
            fontWeight={700}
            sx={(theme) => ({
              background:
                theme.palette.mode === 'dark'
                  ? 'linear-gradient(180deg, rgba(168, 233, 129, 0.6) 0%, rgba(50, 182, 243, 0.6) 100%)'
                  : 'linear-gradient(180deg, rgba(168, 233, 129, 0.6) 0%, rgba(13, 13, 13, 0.6) 100%)',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              color: 'transparent',
            })}
          >
            {!isLoading ? formatter.format(assetsScanned ?? 0) : 0}
          </Typography>
        </ListItem>
      </List>
      <Typography
        mb={0.5}
        fontWeight={600}
        variant="body1"
        color="text.secondary"
      >
        Assets Scanned
      </Typography>
      {/* TODO: remove if not needed later */}
      {/* <Typography fontSize={11} sx={{ color: 'rgba(11, 151, 216, 1)' }}>
        +18% from 7 days ago
      </Typography> */}
    </Card>
  )
}

export default AssetsScanned
