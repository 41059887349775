import { useQuery } from '@tanstack/react-query'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { AwsStatsClient } from 'ui-v2/src/lib/clients'
import { AwsStatsRequestParams } from 'ui-v2/src/lib/models/aws-stats'
import { QUERY_REFETCH_INTERVAL } from 'ui-v2/src/lib/constants/query.constant'
import { NormalizedCoverageCardData } from 'ui-v2/src/lib/models/aws-stats'
import { COVERAGE_CARD_SLUG } from 'ui-v2/src/lib/constants/stats.constant'
import { BASE_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'
import { useMemo } from 'react'

export function useAwsBackupCoverageQuery(filters: AwsStatsRequestParams) {
  const awsStatsClient = useGrpcClient(AwsStatsClient)

  const { data: awsBackupCoverageData, isLoading } = useQuery({
    queryKey: ['aws-statistics', 'backup-coverage', filters],
    queryFn: async () => {
      if (!awsStatsClient) {
        throw new Error('Aws stats client not initialized')
      }
      return awsStatsClient.getBackupCoverage(filters)
    },
    enabled: !!awsStatsClient,
    refetchInterval: QUERY_REFETCH_INTERVAL,
  })

  const normalizedData: Array<NormalizedCoverageCardData> = useMemo(() => {
    if (!awsBackupCoverageData) {
      return []
    }

    const services = ['ebs', 'ec2'] as const

    const coveredCount = services.reduce(
      (sum, service) => sum + (awsBackupCoverageData[service]?.covered ?? 0),
      0
    )

    const totalCount = services.reduce(
      (sum, service) => sum + (awsBackupCoverageData[service]?.total ?? 0),
      0
    )

    return [
      {
        slug: COVERAGE_CARD_SLUG.BACKUP_COVERAGE,
        count: coveredCount,
        totalCount,
        link: `/${BASE_ROUTE_SEGMENTS.ASSETS}`,
      },
    ]
  }, [awsBackupCoverageData])

  return {
    normalizedData,
    isLoading,
  }
}
