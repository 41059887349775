/* eslint-disable import/no-extraneous-dependencies */
import { ChangeEvent, useEffect } from 'react'
import { Stack, Typography, FormControl, MenuItem } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { Link } from 'react-router-dom'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'
import { Controller, useFormContext } from 'react-hook-form'
import { SourceFormData } from 'ui-v2/src/lib/models/settings/sources/sources'
import RegexConstants from 'ui-v2/src/lib/constants/regex.constant'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { VIRow } from 'ui-v2/src/lib/engine-types'
import {
  CloudFormationStackButton,
  ReviewElastioButton,
} from '../aws-account-buttons'
import Box from '@mui/material/Box'

interface AwsAccountProps {
  previousEmittedAccountId: string
  setPreviousEmittedAccountId: React.Dispatch<React.SetStateAction<string>>
  onAccountIdChange: (awsAccountId: string) => void
  listSupportedAwsRegionsData: VIRow | undefined
  disabledVpc: boolean
  isFinishedAccountLevelCFL: boolean
  shouldInstallCfn: boolean
}

const AwsAccount = ({
  previousEmittedAccountId,
  setPreviousEmittedAccountId,
  onAccountIdChange,
  listSupportedAwsRegionsData,
  disabledVpc,
  isFinishedAccountLevelCFL,
  shouldInstallCfn,
}: AwsAccountProps) => {
  const { control, setValue, watch, trigger } = useFormContext<SourceFormData>()

  const awsAccountId = watch('awsAccountId')
  const awsRegionId = watch('awsRegionId')
  const vpcAutoCreate = watch('isVpcAutoCreate')

  const isShowAwsAccountButtons = isFinishedAccountLevelCFL && !vpcAutoCreate

  const isShowCloudFormationButtons =
    shouldInstallCfn &&
    isShowAwsAccountButtons &&
    !!awsAccountId &&
    !!awsRegionId

  const handleAwsAccountId = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = e.target.value
    const filteredValue = inputValue.replace(/[^0-9]/g, '')
    setValue('awsAccountId', filteredValue, { shouldValidate: true })
  }

  const handleRegionChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value as string
    setValue('awsRegionId', value, { shouldValidate: true })
  }

  const handleAutomaticallyVpcChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked
    setValue('isVpcAutoCreate', value)
  }

  const handleVpcRegionChange = (e: SelectChangeEvent<Array<string>>) => {
    const value = e.target.value as Array<string>
    setValue('vpcRegions', value)
  }

  useEffect(() => {
    if (awsAccountId && awsAccountId !== previousEmittedAccountId) {
      trigger('awsAccountId').then((isValid) => {
        if (isValid) {
          onAccountIdChange(awsAccountId)
          setPreviousEmittedAccountId(awsAccountId)
        }
      })
    }
  }, [awsAccountId, previousEmittedAccountId])

  useEffect(() => {
    if (awsRegionId) {
      trigger('awsRegionId')
    }
  }, [awsRegionId])

  return (
    <Stack maxWidth={700}>
      <Typography mb={0.5} color="text.secondary">
        A single Cloud Connector is deployed in a Dedicated Account, centrally
        scanning and managing resources from multiple Protected Accounts.
      </Typography>
      <Typography mb={3.25} color="text.secondary">
        This page guides you through the deployment of the Cloud Connector in an
        Elastio dedicated AWS account. configuring the deployment VPCs and
        regions, and launching the required infrastructure using an Elastio
        CloudFormation stack.
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={4}>
        <Grid size={12}>
          <Typography variant="body2" mb={1} fontWeight={600}>
            Enter Elastio Dedicated AWS Account
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid size={3}>
              <FormControl>
                <Controller
                  control={control}
                  rules={{
                    required: 'AWS account ID is required',
                    pattern: {
                      value: RegexConstants.ACCOUNT_ID,
                      message: 'Account id must contain 12 numbers',
                    },
                  }}
                  name="awsAccountId"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      size="small"
                      error={!!fieldState.error?.message}
                      helperText={fieldState.error?.message}
                      onChange={handleAwsAccountId}
                      slotProps={{ htmlInput: { maxLength: 12 } }}
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid size={3}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  rules={{
                    required: 'AWS Region is required',
                  }}
                  name="awsRegionId"
                  render={({ field, fieldState }) => (
                    <Select
                      value={field.value}
                      onChange={handleRegionChange}
                      size="small"
                      fullWidth
                      error={!!fieldState.error?.message}
                    >
                      {listSupportedAwsRegionsData?.map((region) => (
                        <MenuItem value={region.name}>{region.name}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={12}>
          <Link
            style={{
              color: '#44acea',
              textDecoration: 'none',
            }}
            target="_blank"
            to={
              'https://docs.elastio.com/docs/get-started/deployment-specifications/cloud-connector-security'
            }
          >
            Why are we asking for your account number?
          </Link>
        </Grid>
        <Grid size={12}>
          <Typography variant="body2" fontWeight={600} mb={1}>
            Enter Elastio Dedicated AWS Account
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid size={5}>
              <FormControl>
                <Controller
                  control={control}
                  name="isVpcAutoCreate"
                  render={({ field }) => {
                    return (
                      <Stack direction="row" alignItems="center">
                        <Checkbox
                          checked={field.value}
                          size="small"
                          disabled={disabledVpc}
                          onChange={handleAutomaticallyVpcChange}
                        />
                        <Typography variant="body1">
                          Automatically Create VPCs
                        </Typography>
                      </Stack>
                    )
                  }}
                />
              </FormControl>
            </Grid>
            <Grid size={3}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="vpcRegions"
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      onChange={handleVpcRegionChange}
                      size="small"
                      disabled={!vpcAutoCreate}
                      fullWidth
                      multiple
                    >
                      {listSupportedAwsRegionsData?.map((region) => (
                        <MenuItem value={region.name}>{region.label}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {isShowCloudFormationButtons && (
          <Grid size={12}>
            <Box my={2}>
              <CloudFormationStackButton />
            </Box>
            <Typography variant="body1" fontWeight={500} mb={2}>
              For reference, the CloudFormation template is available here for
              your inspection.
            </Typography>
            <Box>
              <ReviewElastioButton />
            </Box>
          </Grid>
        )}
      </Grid>
    </Stack>
  )
}

export default AwsAccount
