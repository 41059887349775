import { useMemo, useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { useListWebhooksQuery } from 'ui-v2/src/hooks/queries/webhooks'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import {
  getWebhookColumns,
  getWebhookRowActions,
  WebhookAction,
} from './column-config'
import WebhookActionConfirmation from './action-confirmation'
import AddWebhookDrawer from './manage-webhook-drawer'
import { useNavigate } from 'react-router-dom'
import {
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import ErrorDisplay from 'ui-v2/src/components/common/error-display'
import LoadingDisplay from 'ui-v2/src/components/common/loading-display'
import WebhookInvocations from './webhook-invocations'

const Webhooks = () => {
  const { data, status } = useListWebhooksQuery()
  const [action, setAction] = useState<WebhookAction | undefined>(undefined)
  const navigate = useNavigate()

  const columns = useMemo(getWebhookColumns, [])
  const rowActions = useMemo(() => {
    return getWebhookRowActions(setAction, navigate)
  }, [])

  const openAddWebhookDrawer = () => {
    navigate(
      `/settings/${SETTINGS_ROUTE_SEGMENTS.WEBHOOKS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.ADD_WEBHOOK}`
    )
  }

  if (status === 'pending') {
    return <LoadingDisplay />
  }

  if (status === 'error') {
    return (
      <ErrorDisplay message="Error fetching webhook data, please try again later." />
    )
  }

  return (
    <>
      <Stack
        spacing={1}
        direction="row"
        alignItems={'center'}
        justifyContent={'space-between'}
        mb={2}
      >
        {status === 'success' ? (
          <Typography fontWeight={500} variant="body2">
            {data.length} Webhook
            {data.length === 1 ? '' : 's'}
          </Typography>
        ) : null}
        <Button
          variant="contained"
          color="primary"
          sx={{ textTransform: 'capitalize' }}
          onClick={openAddWebhookDrawer}
        >
          Create New Webhook
        </Button>
      </Stack>
      <ClientTable
        data={data ?? []}
        columns={columns}
        rowActions={rowActions}
      />
      <WebhookInvocations data={data ?? []} />
      <WebhookActionConfirmation
        key={`${action?.row?.id ?? '-1'}.${action?.type ?? 'none'}`}
        action={action}
        setAction={setAction}
      />
      <AddWebhookDrawer />
    </>
  )
}

export default Webhooks
