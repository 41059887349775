import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { useQuery } from '@tanstack/react-query'
import { AssetAccountsClient } from 'ui-v2/src/lib/clients/asset-accounts'

export function useListAssetAccounts() {
  const assetAccountClient = useGrpcClient(AssetAccountsClient)

  return useQuery({
    queryKey: ['asset-accounts', 'list'],
    queryFn: () => {
      if (!assetAccountClient) {
        throw new Error('Asset Accounts Client not initialized')
      }
      return assetAccountClient?.listAssetAccounts()
    },
    enabled: !!assetAccountClient,
  })
}
