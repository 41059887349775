/* eslint-disable import/no-extraneous-dependencies */
import {
  AssetAccount,
  CloudConnectorStatus,
  CloudConnector,
} from 'blues-corejs/dist'
import { ActiveDeploymentTableData, AccountStatusConstant } from './types'
import VaultModel from 'ui-v2/src/lib/models/settings/policies/vault.model'

function groupedByAwsAccountFun(
  arr: Array<CloudConnector>
): Record<string, any> {
  const result: Record<string, any> = {}

  arr.map((rs) => {
    if (result[rs.awsAccountId] === undefined) {
      result[rs.awsAccountId] = {
        accountAlias: rs.accountAlias,
        accountDescription: rs.accountDescription,
        awsRegions: [],
        lastErrorMessages: [],
        statuses: [],
        assetsCount: 0,
        awsAccount: rs.awsAccountId,
        redStackAccount: rs.redStackId,
        lastWarningMessage: rs.lastWarningMessage,
        cfnUpgradeRequired: rs.cfnUpgradeRequired,
        createdAt: rs.createdAt,
        updatedAt: rs.updatedAt,
        redStackIds: [rs.redStackId],
      }
    }
    result[rs.awsAccountId].awsRegions.push(rs.awsRegion)
    result[rs.awsAccountId].lastErrorMessages.push(rs.lastErrorMessage)
    result[rs.awsAccountId].statuses.push(rs.status)
    result[rs.awsAccountId].redStackIds.push(rs.redStackId)

    return result
  })
  return result
}

export const getActiveDeploymentsTableData = ({
  allActiveRedStacksData,
  vaultListData,
  listAssetAccounts,
}: {
  allActiveRedStacksData?: Array<CloudConnector>
  vaultListData?: Array<VaultModel>
  listAssetAccounts?: Array<AssetAccount>
}): Array<ActiveDeploymentTableData> => {
  const connectAndDisconnectSources =
    allActiveRedStacksData?.filter(
      (row) =>
        row.isActive ||
        row.isInactive ||
        row.status === CloudConnectorStatus.UPGRADE_REQUIRED
    ) ?? []
  const groupedByAwsAccount: Record<string, any> = groupedByAwsAccountFun(
    connectAndDisconnectSources
  )
  // add count of assetsCount
  vaultListData?.forEach((rowVault) => {
    if (groupedByAwsAccount[rowVault.accountId]) {
      groupedByAwsAccount[rowVault.accountId].assetsCount +=
        rowVault.assetsCount
    }
  })
  const uniqueEnabledSources = [...Object.values(groupedByAwsAccount)]

  return uniqueEnabledSources.map((row: any) => {
    // general account status and label
    let activeRSCount = 0
    let inactiveRSCount = 0
    row.statuses.forEach((value: number) => {
      if (value === CloudConnectorStatus.ACTIVE) {
        activeRSCount += 1
      }
      if (value === CloudConnectorStatus.INACTIVE) {
        inactiveRSCount += 1
      }
    })
    let accountStatusValue = AccountStatusConstant.MIXED

    if (activeRSCount === 0) {
      accountStatusValue = AccountStatusConstant.DISCONNECTED
    }
    if (inactiveRSCount === 0) {
      accountStatusValue = AccountStatusConstant.CONNECTED
    }
    if (row.cfnUpgradeRequired) {
      accountStatusValue = AccountStatusConstant.UPGRADE_REQUIRED
    }

    // fill message
    const messages: Array<string> = []
    if (
      accountStatusValue === AccountStatusConstant.DISCONNECTED ||
      accountStatusValue === AccountStatusConstant.MIXED
    ) {
      row.awsRegions.forEach((region: string, index: number) => {
        let messageValue = ''
        if (row.statuses[index] === CloudConnectorStatus.ACTIVE) {
          messageValue = 'Success'
        }
        if (row.statuses[index] === CloudConnectorStatus.INACTIVE) {
          messageValue = 'Failed'
        }
        messageValue = `${messageValue} - ${region}`
        if (row.lastErrorMessages[index]) {
          messageValue += `: "${row.lastErrorMessages[index]}"`
        }
        messages.push(messageValue)
      })
    }

    return {
      redStackIds: row.redStackIds,
      id: row.awsAccount,
      awsAccount: row.awsAccount,
      accountAlias: row.accountAlias,
      awsRegions: row.awsRegions,
      updatedAt: row.updatedAt,
      statuses: row.statuses,
      status: accountStatusValue,
      messages: messages,
      assetsAccount: listAssetAccounts?.filter(
        (account) => account.connectorAwsAccountId === row.awsAccount
      ),
    }
  })
}
