/* eslint-disable import/no-extraneous-dependencies */
import { ProtobufJob } from 'ui-v2/src/lib/grpc'
import { PercentageProgress, Progress } from 'blues-corejs/dist'
import {
  JobCloudConnector,
  PhasedProgress,
  UnitsProgress,
} from 'blues-corejs/dist'
import { MeasurementUnit } from 'blues-corejs/dist/models/jobs/cloud-connector/progress/units-progress'
import { secondsToDate } from 'ui-v2/src/lib/helpers/time.helper'
import {
  CloudConnectorJobStatus,
  JobCloudConnectorKind,
} from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'
import {
  Asset,
  EBS,
  EC2,
  EFS,
  GenericHost,
  OvaServer,
  S3Bucket,
} from 'blues-corejs/dist'
import { CloudConnectorJobStatusTransformer } from './cloud-connector-job-status'
import { CloudConnectorJobKindTransformer } from './cloud-connector-job-kind'
import { AssetsTab, ListAssetsByIds } from 'ui-v2/src/lib/models/assets'

type JobPt = ProtobufJob.AsObject

export class CloudConnectorJobTransformer {
  #job: JobPt

  constructor(job: JobPt) {
    this.#job = job
  }

  transform(): JobCloudConnector {
    return this.#transformJob(this.#job)
  }

  #transformJob(job: JobPt): JobCloudConnector {
    return new JobCloudConnector({
      id: job.id,
      ccId: job.ccId,
      ccJobId: job.ccJobId,
      name: job.name,
      parentId: job.parentId,
      scanIds: job.scanIdsList,
      status: new CloudConnectorJobStatusTransformer(job.status).transform(),
      statusMessage: job.statusMessage,
      targetAssetId: job.targetAssetId,
      finalized: job.finalized,
      abortRequested: job.abortRequested,
      kind: new CloudConnectorJobKindTransformer(job.kind).transform(),
      progress: this.#convertProgress(
        job.progress as ProtobufJob.Progress.AsObject
      ),
      createdAt: secondsToDate(job.createdAt?.seconds),
      updatedAt: secondsToDate(job.updatedAt?.seconds),
      finishedAt: secondsToDate(job.finishedAt?.seconds),
      startedAt: secondsToDate(job.startedAt?.seconds),
      childrenList: job.childrenList.map((child) => this.#transformJob(child)),
    })
  }

  #convertProgress(
    progress?: ProtobufJob.Progress.AsObject
  ): Progress | undefined {
    if (!progress) {
      return
    }

    const { percentage, phased, units } = progress

    if (percentage) {
      return new PercentageProgress({
        fraction: percentage.fraction?.value ?? 0,
        rate: percentage.rate?.value ?? 0,
      })
    }

    if (phased) {
      return new PhasedProgress({
        currentPhase: phased.currentPhase?.value ?? 0,
        phases: phased.phasesList,
      })
    }

    if (units) {
      return new UnitsProgress({
        current: units.current?.value ?? 0,
        expected: units.expected?.value ?? 0,
        measurement: this.#convertMeasurementUnit(units.measurement),
        rate: units.rate?.value ?? 0,
      })
    }
  }

  #convertMeasurementUnit(
    measurementUnit: ProtobufJob.Progress.Units.Measurement
  ): MeasurementUnit {
    const mapping = {
      [ProtobufJob.Progress.Units.Measurement.BYTES]: MeasurementUnit.BYTES,
    }

    return mapping[measurementUnit]
  }
}

export function getHumanReadableCloudConnectorJobKind(
  kind: JobCloudConnectorKind
): string {
  const mapping: Record<JobCloudConnectorKind, string> = {
    [JobCloudConnectorKind.UNKNOWN]: 'Unknown',
    [JobCloudConnectorKind.EBS_BACKUP]: 'EBS Backup',
    [JobCloudConnectorKind.EBS_RESTORE]: 'EBS Restore',
    [JobCloudConnectorKind.EC2_BACKUP]: 'EC2 Backup',
    [JobCloudConnectorKind.EC2_RESTORE]: 'EC2 Restore',
    [JobCloudConnectorKind.EFS_BACKUP]: 'EFS Backup',
    [JobCloudConnectorKind.ROLLUP]: 'Rollup',
    [JobCloudConnectorKind.BLOCK_BACKUP]: 'Block Backup',
    [JobCloudConnectorKind.BLOCK_RESTORE]: 'Block Restore',
    [JobCloudConnectorKind.FILE_BACKUP]: 'File Backup',
    [JobCloudConnectorKind.FILE_RESTORE]: 'File Restore',
    [JobCloudConnectorKind.STREAM_BACKUP]: 'Stream Backup',
    [JobCloudConnectorKind.STREAM_RESTORE]: 'Stream Restore',
    [JobCloudConnectorKind.ISCAN]: 'iScan',
    [JobCloudConnectorKind.IMPORT_SNAPSHOTS]: 'Import Snapshots',
    [JobCloudConnectorKind.BACKUP_INTEGRITY_CHECK]: 'Backup Integrity Check',
    [JobCloudConnectorKind.APPLY_RETENTION_POLICY]: 'Apply Retention Policy',
    [JobCloudConnectorKind.FS_CHECK]: 'FS Check',
    [JobCloudConnectorKind.POSTGRES_BACKUP]: 'Postgres Backup',
    [JobCloudConnectorKind.POSTGRES_RESTORE]: 'Postgres Restore',
    [JobCloudConnectorKind.AWS_BACKUP_IMPORT]: 'AWS Backup Import',
    [JobCloudConnectorKind.VAULT_CATALOG_RESTORE]: 'Vault Catalog Restore',
    [JobCloudConnectorKind.DATABASE_MAINTENANCE]: 'Database Maintenance',
    [JobCloudConnectorKind.POSTGRES_RECOVERY_TEST]: 'Postgres Recovery Test',
    [JobCloudConnectorKind.S3_BACKUP]: 'S3 Backup',
    [JobCloudConnectorKind.S3_RESTORE]: 'S3 Restore',
    [JobCloudConnectorKind.AWS_BACKUP_RP_ISCAN]: 'AWS Backup RP IScan',
    [JobCloudConnectorKind.AWS_BACKUP_RP_EC2_SCAN]: 'AWS Backup RP EC2 Scan',
    [JobCloudConnectorKind.AWS_BACKUP_RP_EBS_SCAN]: 'AWS Backup RP EBS Scan',
    [JobCloudConnectorKind.AWS_BACKUP_RP_EFS_SCAN]: 'AWS Backup RP EFS Scan',
    [JobCloudConnectorKind.AWS_EFS_SCAN]: 'AWS EFS Scan',
    [JobCloudConnectorKind.AWS_BACKUP_RP_EC2_IMPORT]:
      'AWS Backup RP EC2 Import',
    [JobCloudConnectorKind.AWS_BACKUP_RP_EBS_IMPORT]:
      'AWS Backup RP EBS Import',
    [JobCloudConnectorKind.VAULT_REPLICATION]: 'Vault Replication',
    [JobCloudConnectorKind.AWS_EC2_AMI_SCAN]: 'AWS EC2 AMI Scan',
    [JobCloudConnectorKind.AWS_FSX_ONTAP_SCAN]: 'AWS FSX ONTAP Scan',
    [JobCloudConnectorKind.AWS_EBS_SCAN]: 'AWS EBS Scan',
    [JobCloudConnectorKind.AWS_EC2_SCAN]: 'AWS EC2 Scan',
    [JobCloudConnectorKind.AWS_BACKUP_RP_FSX_ONTAP_SCAN]:
      'AWS Backup RP FSX ONTAP Scan',
    [JobCloudConnectorKind.AWS_EC2_AMI_IMPORT]: 'AWS EC2 AMI Import',
    [JobCloudConnectorKind.AWS_EBS_SNAPSHOT_SCAN]: 'AWS EBS Snapshot Scan',
    [JobCloudConnectorKind.AWS_S3_SCAN]: 'AWS S3 Scan',
    [JobCloudConnectorKind.KIND_AWS_EBS_SNAPSHOTS_SCAN]:
      'AWS EBS Snapshots Scan',
    [JobCloudConnectorKind.KIND_AWS_BACKUP_RP_S3_SCAN]: 'AWS Backup RP S3 Scan',
    [JobCloudConnectorKind.KIND_AWS_BACKUP_RP_VM_SCAN]: 'AWS Backup RP VM Scan',
    [JobCloudConnectorKind.KIND_AWS_ASSET_REGION_STACK_DEPLOY]:
      'AWS Asset Region Stack Deploy',
    [JobCloudConnectorKind.KIND_AWS_ASSET_REGION_STACK_DESTROY]:
      'AWS Asset Region Stack Destroy',
    [JobCloudConnectorKind.KIND_AWS_ASSET_REGION_STACK_UPDATE]:
      'AWS Asset Region Stack Update',
  }

  const humanReadableJobKind = mapping[kind]

  // if (!humanReadableJobKind) {
  //   const errorMessage = job
  //     ? `Got unknown cloud connector job kind for job with id ${job.id}, probably UI models are outdated`
  //     : 'Got unknown cloud connector job kind for job, probably UI models are outdated'

  //   SystemHelper.sendSentryIfProd(errorMessage)
  // }

  return humanReadableJobKind || 'Unknown job kind'
}

export function getNotEmptyAsset(
  assetsList: ListAssetsByIds['assetsList'][number]
) {
  return [
    assetsList.ebsVolume,
    assetsList.ec2Instance,
    assetsList.efsFilesystem,
    assetsList.genericHost,
    assetsList.s3Bucket,
    assetsList.ovaServer,
  ].find((asset) => asset !== undefined)
}

export function computeAssetAccount(asset: Asset | undefined): string {
  if (!asset) {
    return ''
  }
  if (asset instanceof EC2) {
    return asset.awsAccountId
  }

  if (asset instanceof EBS) {
    return asset.awsAccountId
  }

  if (asset instanceof S3Bucket) {
    return asset.awsAccountId
  }

  if (asset instanceof EFS) {
    return asset.awsAccountId
  }

  if (asset instanceof GenericHost) {
    return 'Generic Host'
  }

  if (asset instanceof OvaServer) {
    return 'Server'
  }

  console.error('Unknown asset kind')
  return ''
}

export function computeAssetID(asset: Asset | undefined): string {
  if (!asset) {
    return ''
  }
  if (asset instanceof EC2) {
    return asset.awsId
  }

  if (asset instanceof EBS) {
    return asset.awsAccountId
  }

  if (asset instanceof S3Bucket) {
    return asset.awsAccountId
  }

  if (asset instanceof EFS) {
    return asset.awsAccountId
  }

  if (asset instanceof GenericHost) {
    return 'Generic Host'
  }

  if (asset instanceof OvaServer) {
    return 'Server'
  }

  console.error('Unknown asset kind')
  return ''
}

export function computeAssetRegion(asset: Asset | undefined): string {
  if (!asset) {
    return ''
  }
  if (asset instanceof EC2) {
    return asset.awsRegion
  }

  if (asset instanceof EBS) {
    return asset.awsRegion
  }

  if (asset instanceof S3Bucket) {
    return asset.awsRegion
  }

  if (asset instanceof EFS) {
    return asset.awsRegion
  }

  if (asset instanceof GenericHost) {
    return 'Generic Host'
  }

  if (asset instanceof OvaServer) {
    return 'Server'
  }

  console.error('Unknown region')
  return ''
}

export function computeAssetKind(asset: Asset | undefined): string {
  if (!asset) {
    return ''
  }
  if (asset instanceof EC2) {
    return 'EC2'
  }

  if (asset instanceof EBS) {
    return 'EBS'
  }

  if (asset instanceof S3Bucket) {
    return 'S3'
  }

  if (asset instanceof EFS) {
    return 'EFS'
  }

  if (asset instanceof GenericHost) {
    return 'Generic Host'
  }

  if (asset instanceof OvaServer) {
    return 'Server'
  }
  return ''
}

export function computeAssetKindColor(asset: Asset | undefined): {
  border: string
  backgroundColor: string
} {
  if (!asset) {
    return {
      border: 'solid 1 #1AA8EA',
      backgroundColor: '#1AA8EA',
    }
  }
  if (asset instanceof EC2) {
    return {
      border: 'solid 1 #843911',
      backgroundColor: '#D17F52',
    }
  }

  if (asset instanceof EBS) {
    return {
      border: '#6EA2A0',
      backgroundColor: '#6EA2A0',
    }
  }

  if (asset instanceof S3Bucket) {
    return {
      border: 'solid 1px #046AF0',
      backgroundColor: '#1B59F8',
    }
  }

  if (asset instanceof EFS) {
    return {
      border: 'solid 1px #F2B8B5',
      backgroundColor: '#F2B8B5',
    }
  }

  if (asset instanceof GenericHost) {
    return {
      border: 'solid 1px #CB928F',
      backgroundColor: '#C86D69',
    }
  }

  if (asset instanceof OvaServer) {
    return {
      border: 'solid 1px #9747FF',
      backgroundColor: '#C29EF1',
    }
  }
  return {
    border: 'solid 1px #1AA8EA',
    backgroundColor: '#1AA8EA',
  }
}

export const CIRCLE_STATUS_COLOR = {
  [CloudConnectorJobStatus.SUCCEEDED]: '#27ae60',
  [CloudConnectorJobStatus.IN_PROGRESS]: '#fec53d',
  [CloudConnectorJobStatus.SCHEDULED]: '#f2994a',
  [CloudConnectorJobStatus.FAILED]: '#eb5757',
  [CloudConnectorJobStatus.ABORTED]: '#dfeaf5',
  [CloudConnectorJobStatus.STARTING]: '#dfeaf5',
}

export const STATUS_TO_STYLE_VARIANT_MAPPING = {
  [CloudConnectorJobStatus.SCHEDULED]: 'Pending',
  [CloudConnectorJobStatus.STARTING]: 'Pending',
  [CloudConnectorJobStatus.IN_PROGRESS]: 'Progress',
  [CloudConnectorJobStatus.FAILED]: 'Failed',
  [CloudConnectorJobStatus.SUCCEEDED]: 'Success',
  [CloudConnectorJobStatus.ABORTED]: 'Aborted',
}

export function mapCloudConnectorJobStatusColorVariant(
  status: CloudConnectorJobStatus
) {
  return CIRCLE_STATUS_COLOR[status]
}

export function mapCloudConnectorJobStatusToStyleVariant(
  status: CloudConnectorJobStatus
) {
  return STATUS_TO_STYLE_VARIANT_MAPPING[status]
}

export function getHumanReadableCloudConnectorJobAssets(
  tab: AssetsTab
): string {
  const mapping: Record<AssetsTab, string> = {
    [AssetsTab.EC2]: 'EC2',
    [AssetsTab.EBS]: 'EBS',
    [AssetsTab.S3_BUCKET]: 'S3 Bucket',
    [AssetsTab.SERVERS]: 'Servers',
    [AssetsTab.GENERIC_HOST]: 'Generic Host',
  }
  return mapping[tab]
}

export function getCloudConnectorJobStatusMessage(
  message: string,
  tenantJobStatus: CloudConnectorJobStatus
) {
  const mapping = {
    [CloudConnectorJobStatus.SCHEDULED]: 'Scheduled',
    [CloudConnectorJobStatus.STARTING]: 'Starting',
    [CloudConnectorJobStatus.IN_PROGRESS]: 'In Progress',
    [CloudConnectorJobStatus.FAILED]: message,
    [CloudConnectorJobStatus.SUCCEEDED]: 'Completed successfully',
    [CloudConnectorJobStatus.ABORTED]: 'Aborted',
  }
  return mapping[tenantJobStatus]
}
