/* eslint-disable import/no-cycle */
import { Suspense, useState } from 'react'
import { Box, Grid2, Stack } from '@mui/material'
import RadarChartCard from './radar-chart-card'
import MedianRpoCard from './median-rpo-card'
import ReturnInvestmentCard from './return-investment-card'
import NewsFeed from './news-feed'
import RadarSections from './radar-sections'
import ActionCards from './action-cards'
import { RadarChartAxes } from 'ui-v2/src/lib/constants/ui.constant'

const Dashboard = () => {
  const preSelectedSection = localStorage.getItem('selectedSection')
  const [selectedSection, setSelectedSection] = useState<RadarChartAxes>(
    (preSelectedSection as RadarChartAxes) || RadarChartAxes.RANSOMWARE_SAFETY
  )

  const handleSelectRadarSection = (section: RadarChartAxes) => {
    localStorage.setItem('selectedSection', section)
    setSelectedSection(section)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
      }}
    >
      <Grid2 container spacing={2}>
        <Grid2
          size={{
            xs: 12,
            sm: 12,
            md: 12,
            lg: 8,
          }}
        >
          <Grid2 container spacing={2}>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
              }}
            >
              <RadarChartCard
                selectedSection={selectedSection}
                setSelectedSection={handleSelectRadarSection}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 6,
              }}
            >
              <MedianRpoCard />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 6,
              }}
            >
              <ReturnInvestmentCard />
            </Grid2>
            <Stack
              sx={{
                flexGrow: 0,
                flexBasis: 'auto',
              }}
            >
              <NewsFeed />
            </Stack>
          </Grid2>
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 12,
            md: 12,
            lg: 4,
          }}
        >
          <Stack spacing={2}>
            <Grid2
              container
              spacing={2}
              rowGap={2}
              flexDirection={{
                xs: 'column-reverse',
                md: 'row',
                lg: 'row',
              }}
            >
              <Grid2
                size={{
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 12,
                }}
              >
                <RadarSections selectedSection={selectedSection} />
              </Grid2>
              <Grid2
                size={{
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 12,
                }}
              >
                <Stack spacing={2}>
                  <Suspense fallback="Loading...">
                    <ActionCards />
                  </Suspense>
                </Stack>
              </Grid2>
            </Grid2>
          </Stack>
        </Grid2>
      </Grid2>
    </Box>
  )
}

export default Dashboard
