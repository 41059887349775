/* eslint-disable import/no-extraneous-dependencies */
import { EC2_STATE_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import {
  Backup,
  BackupPoliciesArray,
  EBS,
  EC2,
  Nullable,
  Tag,
  Threat,
} from 'blues-corejs/dist'
import { PolicyCoverageState } from '../common'
import { Scan } from 'ui-v2/src/lib/models/scans'
import { ListEc2Request } from 'ui-v2/src/lib/grpc'

export interface ListEc2Filters {
  assetIdList?: Array<string>
  accountIdsList?: Array<string>
  regionsList?: Array<string>
  tagsList?: Array<Tag>
  instanceStatesList?: Array<ListEc2Request.Initial.Filter.InstanceState>
  protectionPolicyCoverageStatesList?: Array<PolicyCoverageState>
  search?: string
  pageSize?: number
  pageToken?: string
}

export interface AssetsEc2TableDataAttrs {
  instances: Array<EC2>
  volumes: Array<EBS>
  threats: Array<Threat>
  scans: Array<Scan>
}

export interface AssetsEc2DrawerDataAttrs {
  instances: Array<EC2>
  volumes: Array<EBS>
  threats: Array<Threat>
  lastBackups: Map<string, Backup>
  scans: Array<Scan>
}

interface BaseEc2Attrs {
  id: string
  name: string
  awsId: string
  awsAccountId: string
  awsRegion: string
  state: (typeof EC2_STATE_MAP)[keyof typeof EC2_STATE_MAP]
  isUnhealthy: boolean
  lastScan: Nullable<Scan>
}

export abstract class BaseEc2 {
  readonly #id: string

  readonly #name: string

  readonly #awsId: string

  readonly #state: (typeof EC2_STATE_MAP)[keyof typeof EC2_STATE_MAP]

  readonly #isUnhealthy: boolean

  readonly #awsAccountId: string

  readonly #awsRegion: string

  readonly #lastScan: Nullable<Scan>

  constructor(parameters: BaseEc2Attrs) {
    const {
      id,
      name,
      awsId,
      awsAccountId,
      awsRegion,
      state,
      isUnhealthy,
      lastScan,
    } = parameters

    this.#id = id
    this.#name = name
    this.#awsId = awsId
    this.#awsAccountId = awsAccountId
    this.#awsRegion = awsRegion
    this.#state = state
    this.#isUnhealthy = isUnhealthy
    this.#lastScan = lastScan
  }

  get id(): string {
    return this.#id
  }

  get name(): string {
    return this.#name
  }

  get awsId(): string {
    return this.#awsId
  }

  get awsAccountId(): string {
    return this.#awsAccountId
  }

  get awsRegion(): string {
    return this.#awsRegion
  }

  get state(): (typeof EC2_STATE_MAP)[keyof typeof EC2_STATE_MAP] {
    return this.#state
  }

  get isUnhealthy(): boolean {
    return this.#isUnhealthy
  }

  get lastScan(): Nullable<Scan> {
    return this.#lastScan
  }
}

export class Ec2TableRow extends BaseEc2 {
  readonly #backupPolicies: BackupPoliciesArray

  readonly #createdAt: Date

  constructor(parameters: Ec2TableRowAttrs) {
    const { backupPolicies, createdAt, ...baseParams } = parameters
    super(baseParams)

    this.#backupPolicies = backupPolicies
    this.#createdAt = createdAt
  }

  get backupPolicies(): BackupPoliciesArray {
    return this.#backupPolicies
  }

  get createdAt(): Date {
    return this.#createdAt
  }
}

export class Ec2Drawer extends BaseEc2 {
  readonly #lastBackup: Nullable<Backup>

  readonly assetKind: string = 'EC2 Instance'

  constructor(parameters: Ec2DrawerAttrs) {
    const { lastBackup, ...baseParams } = parameters
    super(baseParams)

    this.#lastBackup = lastBackup
  }

  get lastBackup(): Nullable<Backup> {
    return this.#lastBackup
  }
}

interface Ec2TableRowAttrs extends BaseEc2Attrs {
  backupPolicies: BackupPoliciesArray
  createdAt: Date
}

interface Ec2DrawerAttrs extends BaseEc2Attrs {
  lastBackup: Nullable<Backup>
}
