/* eslint-disable import/no-extraneous-dependencies */
import { Box, Typography } from '@mui/material'
import ListComponents, {
  ListItems,
} from 'ui-v2/src/components/ui/data-display/list-components'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'
import Button from 'ui-v2/src/components/ui/inputs/button'
import { useGetCloudInformationLinkQuery } from 'ui-v2/src/hooks/queries/red-stack'
import { useParams } from 'react-router-dom'
import { uuid } from 'blues-corejs/dist/use_cases/tests/factories'

const listItems: Array<ListItems> = [
  {
    id: uuid(),
    text: 'Deploy the Elastio Protect Account CFN StackSet in AWS',
    innerItems: [
      {
        id: uuid(),
        text: 'Launch the StackSet in AWS to deploy the Protect Account CFN to your protected accounts. ',
      },
    ],
  },
  {
    id: uuid(),
    text: 'Verify Deployment',
    innerItems: [
      {
        id: uuid(),
        text: 'Check the status of the deployment in AWS.',
      },
      {
        id: uuid(),
        text: 'Once the status shows SUCCEEDED, the setup is complete.',
      },
    ],
  },
]

const LinkProtectedAccountModal = () => {
  const { id } = useParams()
  const { data: cloudInformationLinkData, isLoading } =
    useGetCloudInformationLinkQuery(id)

  const handleDownloadButton = () => {
    window.open(cloudInformationLinkData, '_blank')
  }

  return (
    <Box
      sx={{
        paddingX: 2.5,
        paddingBottom: 2.5,
      }}
    >
      <Box mb={2}>
        <Typography variant="body1" fontWeight={500} mb={1}>
          To link your protected accounts to the Cloud Connector, follow these
          steps to deploy the Protect Account CloudFormation (CFN) template
          using AWS StackSets:
        </Typography>
        <ListComponents wrapperComponent={'ol'} items={listItems} />
        <Typography variant="body1" fontWeight={500} mb={1}>
          The Cloud Connector will automatically link to the protected accounts,
          which will then appear in the Cloud Connector view.
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body1" fontWeight={600} mb={1}>
          Amazon S3 URL
        </Typography>
        <TextField
          placeholder="https://"
          value={cloudInformationLinkData}
          sx={{ width: '100%' }}
          disabled
        />
      </Box>
      <Box>
        <Typography variant="body1" fontWeight={600} mb={1}>
          Download CFN
        </Typography>
        <Button
          variant="contained"
          disabled={isLoading}
          onClick={handleDownloadButton}
        >
          Download
        </Button>
      </Box>
    </Box>
  )
}

export default LinkProtectedAccountModal
