/* eslint-disable import/no-extraneous-dependencies */
import { uuid } from 'blues-corejs/dist/use_cases/tests/factories'
import { useNavigate, useParams } from 'react-router-dom'
import { List, ListItem, Typography, Box } from '@mui/material'
import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import Button from 'ui-v2/src/components/ui/inputs/button'
import ListComponents, {
  ListItems,
} from 'ui-v2/src/components/ui/data-display/list-components'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'
import { useGetCloudInformationLinkQuery } from '../../../../../hooks/queries/red-stack'

const listItems: Array<ListItems> = [
  {
    id: uuid(),
    text: 'Log in to the AWS Management Console',
    innerItems: [
      {
        id: uuid(),
        text: 'Navigate to the AWS account where the Protect Account CFN stack is deployed.',
      },
    ],
  },
  {
    id: uuid(),
    text: 'Open the CloudFormation Service',
    innerItems: [
      {
        id: uuid(),
        text: 'In the AWS Management Console, search for and open the CloudFormation service.',
      },
    ],
  },
  {
    id: uuid(),
    text: 'Locate the Protect Account CFN Stack',
    innerItems: [
      {
        id: uuid(),
        text: 'Find the CloudFormation stack associated with the Protect Account setup.',
      },
    ],
  },
  {
    id: uuid(),
    text: 'Select the Stack to Update',
    innerItems: [
      {
        id: uuid(),
        text: 'Click on the stack name to view its details.',
      },
      {
        id: uuid(),
        text: 'In the stack actions menu, select Update Stack.',
      },
    ],
  },
  {
    id: uuid(),
    text: 'Choose the Update Method',
    innerItems: [
      {
        id: uuid(),
        text: 'Select one of the following options:',
        innerItems: [
          {
            id: uuid(),
            text: 'Use a new template: If you have a new or updated CFN template, upload it or provide the S3 URL.',
          },
          {
            id: uuid(),
            text: 'Use the current template: If no template changes are required, proceed with parameter updates.',
          },
        ],
      },
    ],
  },
  {
    id: uuid(),
    text: 'Modify Stack Parameters (If Needed)',
    innerItems: [
      {
        id: uuid(),
        text: 'Review and update the stack parameters as needed.',
      },
      {
        id: uuid(),
        text: 'Ensure any changes align with your current account configuration.',
      },
    ],
  },
  {
    id: uuid(),
    text: 'Review and Confirm the Update',
    innerItems: [
      {
        id: uuid(),
        text: 'Review the changes on the summary page.',
      },
      {
        id: uuid(),
        text: 'Click Update Stack to proceed.',
      },
    ],
  },
  {
    id: uuid(),
    text: 'Monitor the Update Process',
    innerItems: [
      {
        id: uuid(),
        text: 'Wait for the update to complete.',
      },
      {
        id: uuid(),
        text: 'Verify the stack status shows UPDATE_COMPLETE in the CloudFormation dashboard.',
      },
    ],
  },
]

const UpdateDrawer = () => {
  const { action, id } = useParams()
  const isDrawerOpen =
    action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.UPDATE_PROTECTED_ACCOUNT

  const navigate = useNavigate()
  const { data: cloudInformationLinkData, isLoading } =
    useGetCloudInformationLinkQuery(id)

  const handleCloseDrawer = () => {
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}`
    )
  }

  const handleDownloadButton = () => {
    window.open(cloudInformationLinkData, '_blank')
  }

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
      <DrawerContent>
        <List sx={{ paddingX: 0 }}>
          <ListItem
            sx={{
              paddingX: 2.5,
              paddingRight: '70px',
            }}
            secondaryAction={
              <Button variant="contained" onClick={handleCloseDrawer}>
                Close
              </Button>
            }
          >
            <Typography fontWeight={700} fontSize={22}>
              Update Elastio Protected Account CFN
            </Typography>
          </ListItem>
        </List>
        <Box
          sx={{
            paddingX: 2.5,
            paddingBottom: 2.5,
          }}
        >
          <Box mb={2}>
            <Typography variant="body1" fontWeight={500} mb={1}>
              To ensure your protected account is using the latest
              configuration, follow these steps to update the Protect Account
              CFN stack:
            </Typography>
            <ListComponents wrapperComponent={'ol'} items={listItems} />
          </Box>
          <Box mb={2}>
            <Typography variant="body1" fontWeight={600} mb={1}>
              Amazon S3 URL
            </Typography>
            <TextField
              placeholder="https://"
              value={cloudInformationLinkData}
              sx={{ width: '100%' }}
              disabled
            />
          </Box>
          <Box>
            <Typography variant="body1" fontWeight={600} mb={1}>
              Download CFN
            </Typography>
            <Button
              variant="contained"
              disabled={isLoading}
              onClick={handleDownloadButton}
            >
              Download
            </Button>
          </Box>
        </Box>
      </DrawerContent>
    </Drawer>
  )
}

export default UpdateDrawer
