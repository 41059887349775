import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { HistoryTableRow } from 'ui-v2/src/lib/models/reports'
import dayjs from 'dayjs'
import HistoryAction from './history-action'

export const getHistoryColumns = (): Array<Column<HistoryTableRow>> => [
  {
    key: 'scheduleName',
    header: 'Schedule Name',
    render: (row) => row.scheduleName,
  },
  {
    key: 'description',
    header: 'Description',
    render: (row) => (row.description ? row.description : '-'),
  },
  {
    key: 'reportType',
    header: 'Report Type',
    render: (row) => row.reportType,
  },
  {
    key: 'rangeOfData',
    header: 'Range of Data',
    render: (row) => (
      <>
        {`${dayjs(row.startDate).format('hh:mm A, MM/DD/YYYY')} - ${dayjs(row.endDate).format('hh:mm A, MM/DD/YYYY')}`}{' '}
      </>
    ),
  },
  {
    key: 'generated',
    header: 'Generated',
    render: (row) => dayjs(row.generatedDate).format('hh:mm A, MM/DD/YYYY'),
  },
  {
    key: 'action',
    header: 'Action',
    render: (row) => <HistoryAction row={row} />,
  },
]
