import { SyntheticEvent, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import AssetsEc2 from './ec2'
import AssetsEbs from './ebs'
import AssetsS3 from './s3'
import AssetsEfs from './efs'
import AssetsOvaServer from './ova-server'
import AssetsGenericHost from './generic-host'
import AssetsDrawer from 'ui-v2/src/components/drawers/asset-details'
import AssetRestoreCleanBackup from 'ui-v2/src/components/drawers/restore-clean-backup'
import { getDefaultQueryParams } from './filters/config'
import { ASSETS_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'

function tabProps(slug: string) {
  return {
    id: `tab-${slug}`,
    'aria-controls': `tabpanel-${slug}`,
  }
}

type TabKey = 'ec2' | 'ebs' | 's3' | 'efs' | 'server' | 'generic-host'

interface TabItem {
  label: string
  component: JSX.Element
  slug: string
}

const TAB_MAP: Record<TabKey, TabItem> = {
  ec2: {
    label: 'EC2',
    component: <AssetsEc2 />,
    slug: ASSETS_ROUTE_SEGMENTS.EC2,
  },
  ebs: {
    label: 'EBS',
    component: <AssetsEbs />,
    slug: ASSETS_ROUTE_SEGMENTS.EBS,
  },
  s3: {
    label: 'S3 Bucket',
    component: <AssetsS3 />,
    slug: ASSETS_ROUTE_SEGMENTS.S3,
  },
  efs: {
    label: 'EFS',
    component: <AssetsEfs />,
    slug: ASSETS_ROUTE_SEGMENTS.EFS,
  },
  server: {
    label: 'Servers',
    component: <AssetsOvaServer />,
    slug: ASSETS_ROUTE_SEGMENTS.SERVER,
  },
  'generic-host': {
    label: 'Generic Host',
    component: <AssetsGenericHost />,
    slug: ASSETS_ROUTE_SEGMENTS.GENERIC_HOST,
  },
}

export default function Assets() {
  const navigate = useNavigate()
  const { tab } = useParams<{
    tab: (typeof ASSETS_ROUTE_SEGMENTS)[keyof typeof ASSETS_ROUTE_SEGMENTS]
  }>()
  const defaultPath = `/assets/${ASSETS_ROUTE_SEGMENTS.EC2}${getDefaultQueryParams(ASSETS_ROUTE_SEGMENTS.EC2)}`

  useEffect(() => {
    if (!tab) {
      navigate(defaultPath, { replace: true })
      return
    }

    if (!Object.values(TAB_MAP).some((t) => t.slug === tab)) {
      navigate(defaultPath, { replace: true })
      return
    }

    if (!location.search) {
      const defaultQueryParams = getDefaultQueryParams(tab)
      if (defaultQueryParams) {
        navigate(`/assets/${tab}${defaultQueryParams}`, { replace: true })
      }
    }
  }, [tab])

  if (!tab || !Object.values(TAB_MAP).some((t) => t.slug === tab)) {
    return null
  }

  const handleChange = (
    _: SyntheticEvent,
    newSlug: (typeof ASSETS_ROUTE_SEGMENTS)[keyof typeof ASSETS_ROUTE_SEGMENTS]
  ) => {
    const defaultQueryParams = getDefaultQueryParams(newSlug)
    navigate(`/assets/${newSlug}${defaultQueryParams}`)
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="asset tabs"
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              paddingLeft: 3,
              paddingRight: 3,

              '& .MuiTab-root.Mui-selected': {
                color: theme.palette.text.primary,
              },
            })}
          >
            {Object.values(TAB_MAP).map((tabItem) => (
              <Tab
                key={tabItem.slug}
                value={tabItem.slug}
                label={tabItem.label}
                sx={{ py: 0 }}
                {...tabProps(tabItem.slug)}
              />
            ))}
          </Tabs>
        </Box>

        {!!TAB_MAP[tab as TabKey] && (
          <Box sx={{ py: 1 }}>{TAB_MAP[tab as TabKey]?.component}</Box>
        )}
      </Box>

      <AssetsDrawer />
      <AssetRestoreCleanBackup />
    </>
  )
}
