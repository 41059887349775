import Button from 'ui-v2/src/components/ui/inputs/button'
import { useGetCloudInformationLinkQuery } from 'ui-v2/src/hooks/queries/red-stack'
import { buildCloudFormationLink } from 'ui-v2/src/lib/helpers/data.helper'
import { useFormContext } from 'react-hook-form'
import { SourceFormData } from 'ui-v2/src/lib/models/settings/sources/sources'

export const CloudFormationStackButton = () => {
  const { getValues } = useFormContext<SourceFormData>()
  const { awsAccountId, awsRegionId } = getValues()
  const { data: cloudInformationLinkData } =
    useGetCloudInformationLinkQuery(awsAccountId)

  const cloudFormationLink = buildCloudFormationLink(
    cloudInformationLinkData ?? '',
    awsRegionId
  )

  if (!cloudInformationLinkData || !awsRegionId) {
    return null
  }

  return (
    <a href={cloudFormationLink} target="_blank">
      <Button variant="contained">Launch CloudFormation Stack</Button>
    </a>
  )
}

export const ReviewElastioButton = () => {
  const { getValues } = useFormContext<SourceFormData>()
  const { awsAccountId } = getValues()
  const { data: cloudInformationLinkData } =
    useGetCloudInformationLinkQuery(awsAccountId)
  return (
    <a href={cloudInformationLinkData} target="_blank">
      <Button
        variant="contained"
        color="inherit"
        href={cloudInformationLinkData}
      >
        Review Elastio CFN
      </Button>
    </a>
  )
}
