import React from 'react'
import {
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
  styled,
  RadioGroupProps as MuiRadioGroupProps,
  FormControl,
  FormHelperText,
} from '@mui/material'
import { Option } from 'ui-v2/src/lib/models/reports'

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'rgba(211, 216, 222, 1)' : '#1A1F28',
}))

const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    {
      options,
      error = false,
      helperText = '',
      ...radioGroupProps
    }: RadioGroupProps,
    ref
  ) => {
    return (
      <FormControl error={error}>
        <MuiRadioGroup {...radioGroupProps} ref={ref}>
          {options.map((option) => (
            <StyledFormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </MuiRadioGroup>
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
)
RadioGroup.displayName = 'RadioGroup'

interface RadioGroupProps extends MuiRadioGroupProps {
  options: Array<Option>
  error?: boolean
  helperText?: string
}

export default RadioGroup
