/* eslint-disable import/no-extraneous-dependencies */
import { GrpcClient } from './grpc'
import { AssetAccountPromiseClient, ListAssetAccountsRequest } from '../grpc'
import { ClientConfig } from '../models/client'
import { AssetAccount } from 'blues-corejs/dist'
import { AssetAccountsTransformer } from '../transformers/rex/asset-accounts'

export class AssetAccountsClient extends GrpcClient<AssetAccountPromiseClient> {
  #assetAccountClient: AssetAccountPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#assetAccountClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'AssetAccountsClient'
  }

  protected initClient(hostName: string): AssetAccountPromiseClient {
    return new AssetAccountPromiseClient(hostName, null, null)
  }

  async listAssetAccounts(): Promise<Array<AssetAccount>> {
    const request = new ListAssetAccountsRequest()
    const response = await this.callGrpcService(
      () =>
        this.#assetAccountClient.listAssetAccounts(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: 'AssetAccountPromiseClient/listAssetAccounts',
      }
    )

    return response.getAssetAccountsList().map((assetAccount) => {
      return new AssetAccountsTransformer(assetAccount).transform()
    })
  }
}
