import ElastioShieldIcon from 'ui-v2/src/assets/icons/elastio-shield-icon'
import { Box, Card, Stack, Typography } from '@mui/material'
import Marquee from 'react-fast-marquee'
import { Link } from 'react-router-dom'
import { NEWS_FEED_DATA } from 'ui-v2/src/lib/constants/news-feed.constant'

function NewsFeed() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1.2,
      }}
    >
      <Card
        elevation={0}
        sx={(theme) => ({
          background:
            theme.palette.mode === 'dark' ? 'rgba(21, 38, 53, 1)' : '#ffffff',
          boxShadow:
            theme.palette.mode === 'dark'
              ? 'none'
              : '0px 16px 20px 0px #A7ACD32B',
        })}
        className="news-feed-card"
      >
        <Marquee pauseOnHover={true} speed={50} autoFill={true}>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            columnGap={1.5}
            marginRight={1.5}
          >
            {NEWS_FEED_DATA.map((newsItem) => (
              <Link
                key={newsItem.id}
                to={newsItem.link}
                style={{
                  textDecoration: 'none',
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box
                  maxWidth={'auto'}
                  minWidth={150}
                  sx={(theme) => ({
                    backgroundColor:
                      theme.palette.mode === 'light' ? '#fff' : '#1A2F47',
                    border:
                      theme.palette.mode === 'light'
                        ? '1px solid #eee'
                        : '1px solid #28425F',

                    '&:hover': {
                      backgroundColor:
                        theme.palette.mode === 'light' ? '#e9ecf0' : '#1e3b5c',
                    },
                  })}
                  component={'div'}
                  className="dashboard-news-feed"
                >
                  <Typography
                    fontSize={22}
                    fontWeight={700}
                    lineHeight={'28px'}
                    color="text.secondary"
                    mb={0.5}
                  >
                    {newsItem.name}
                  </Typography>
                  <Box display={'flex'} alignItems={'center'} columnGap={1}>
                    <ElastioShieldIcon />
                    <Typography
                      fontSize={16}
                      fontWeight={400}
                      lineHeight={'20px'}
                      color="text.secondary"
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {newsItem.updatedAt}
                    </Typography>
                  </Box>
                </Box>
              </Link>
            ))}
          </Stack>
        </Marquee>
      </Card>
    </Box>
  )
}

export default NewsFeed
