/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AssetAccount } from 'blues-corejs/dist'
import ActionMenu from 'ui-v2/src/components/ui/data-table/action-menu'
import {
  BASE_ROUTE_SEGMENTS,
  GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from '../../../../../lib/constants/route-segments.constant'

const unlinkLink = `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.UNLINK_PROTECTED_ACCOUNT}`
const upgradeLink = `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${GENERAL_SETTINGS_SOURCE_ROUTE_SEGMENTS.ACTIVE_DEPLOYMENTS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.UPDATE_PROTECTED_ACCOUNT}`

const MenuItemsExpanded = ({ row }: { row: AssetAccount }) => {
  const navigate = useNavigate()
  const { connectorAwsAccountId } = row

  const menuItems = [
    {
      name: 'Unlink',
      action: () => {
        navigate(unlinkLink)
      },
    },
    {
      name: 'Upgrade',
      action: () => {
        navigate(`${upgradeLink}/${connectorAwsAccountId}`)
      },
    },
  ]

  return <ActionMenu menuItems={menuItems} />
}

export default MenuItemsExpanded
