import { useMemo } from 'react'
import { useListPlanQuery } from 'ui-v2/src/hooks/queries/pechkin'
import { PolicyTableData } from 'ui-v2/src/lib/use-cases/settings/policies'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import { getPolicyListColumns } from './column-config'

import Button from 'ui-v2/src/components/ui/inputs/button'
import { Stack } from '@mui/material'
import PolicyDrawer from './policy-drawer'
import { useNavigate } from 'react-router-dom'
import {
  BASE_ROUTE_SEGMENTS,
  POLICIES_SETTINGS_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'

const Policies = () => {
  const { data: policyList, isLoading } = useListPlanQuery()
  const navigate = useNavigate()

  const columns = useMemo(getPolicyListColumns, [])

  const data = useMemo(() => {
    if (!policyList) {
      return undefined
    }

    return new PolicyTableData({
      policyList,
    }).getPolicyList()
  }, [policyList])

  const handleCreatePolicy = () => {
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.POLICIES}/${POLICIES_SETTINGS_ROUTE_SEGMENTS.PROTECTION_POLICIES}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.CREATE_POLICY}`
    )
  }
  const existingPoliciesNames = data?.map((policy) => policy.id) ?? []

  return (
    <>
      <Stack
        direction="row"
        alignItems={'center'}
        justifyContent={'flex-end'}
        mb={2}
      >
        <Button variant="contained" onClick={handleCreatePolicy}>
          Create New
        </Button>
      </Stack>
      <ClientTable
        checkable={false}
        isLoading={isLoading}
        data={data ?? []}
        columns={columns}
      />
      <PolicyDrawer existingPoliciesNames={existingPoliciesNames} />
    </>
  )
}

export default Policies
