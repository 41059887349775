import React, { useState } from 'react'
import { Paper, Popover, Box } from '@mui/material'
import Calendar from 'react-calendar'
import { styled } from '@mui/material/styles'
import dayjs, { Dayjs } from 'dayjs'
import 'react-calendar/dist/Calendar.css'
import { Value } from 'react-calendar/dist/cjs/shared/types'
import TextField from '../text-field'

interface DateRange {
  startDate: Dayjs
  endDate: Dayjs
}

interface DateRangePickerProps {
  onChange?: (range: DateRange) => void
  initialDateRange?: DateRange
  className?: string
  label?: boolean
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark' ? '#1a1a1a' : '#ffffff',
  '& .react-calendar': {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',

    '& .react-calendar__navigation': {
      '& button': {
        color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
        '&:enabled:hover': {
          backgroundColor:
            theme.palette.mode === 'dark'
              ? 'rgba(25, 118, 210, 0.2)'
              : 'rgba(25, 118, 210, 0.1)',
        },
        '&:enabled:focus': {
          backgroundColor:
            theme.palette.mode === 'dark'
              ? 'rgba(25, 118, 210, 0.25)'
              : 'rgba(25, 118, 210, 0.15)',
        },
      },
    },

    '& .react-calendar__month-view__weekdays': {
      color: theme.palette.mode === 'dark' ? '#888888' : '#666666',
    },

    '& .react-calendar__tile': {
      color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
    },
    '& .react-calendar__tile--now': {
      background:
        theme.palette.mode === 'dark'
          ? 'rgba(25, 118, 210, 0.15)'
          : 'rgba(25, 118, 210, 0.1)',
    },

    '& .react-calendar__tile:disabled': {
      color: theme.palette.mode === 'dark' ? '#666666' : '#cccccc',
    },
  },
}))

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  onChange,
  initialDateRange,
  className,
  label = true,
}) => {
  const defaultDateRange = {
    startDate: dayjs().startOf('month'),
    endDate: dayjs(),
  }

  const [dateRange, setDateRange] = useState<DateRange>(
    initialDateRange || defaultDateRange
  )
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleInputClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDateChange = (value: Value) => {
    if (Array.isArray(value)) {
      const [start, end] = value
      const newRange = {
        startDate: start ? dayjs(start) : defaultDateRange.startDate,
        endDate: end ? dayjs(end) : defaultDateRange.endDate,
      }
      setDateRange(newRange)
      onChange?.(newRange)

      if (end) {
        handleClose()
      }
    }
  }

  const formatDate = (date: Dayjs | null) => {
    if (!date) {
      return ''
    }
    return date.format('MMM D, YYYY')
  }

  return (
    <Box className={className}>
      <Box display="flex" gap={2}>
        <TextField
          label={label ? 'Start Date' : ''}
          value={formatDate(dateRange.startDate)}
          onClick={handleInputClick}
          fullWidth
          size="small"
        />
        <TextField
          label={label ? 'End Date' : ''}
          value={formatDate(dateRange.endDate)}
          onClick={handleInputClick}
          fullWidth
          size="small"
        />
      </Box>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <StyledPaper className="default-calendar">
          <Calendar
            selectRange={true}
            value={
              [
                dateRange.startDate?.toDate(),
                dateRange.endDate?.toDate(),
              ].filter(Boolean) as [Date, Date]
            }
            onChange={handleDateChange}
            minDetail="month"
            returnValue="range"
          />
        </StyledPaper>
      </Popover>
    </Box>
  )
}

export default DateRangePicker
