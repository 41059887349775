import { Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { SourceFormData } from 'ui-v2/src/lib/models/settings/sources/sources'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CircleInfoIcon from 'ui-v2/src/assets/icons/circle-info-icon'
import {
  CloudFormationStackButton,
  ReviewElastioButton,
} from '../aws-account-buttons'

const AwsAccountElastio = () => {
  const { getValues } = useFormContext<SourceFormData>()
  const { awsAccountId, vpcRegions } = getValues()

  return (
    <Box>
      <Typography variant="body1" fontWeight={500} mb={2}>
        The Cloud Connector deployments are currently in progress for the
        following account and regions:
      </Typography>
      <Typography component={'ul'} mb={2}>
        <Typography component={'li'}>
          Dedicated Account: {awsAccountId}
        </Typography>
        {vpcRegions.length > 0 && (
          <Box
            component={'li'}
            sx={{
              listStyleType: 'disc',
              fontWeight: '500',
            }}
          >
            Regions:
            <Box component={'ul'}>
              {vpcRegions.map((region) => (
                <Box
                  component={'li'}
                  key={region}
                  sx={{
                    listStyleType: 'disc',
                  }}
                >
                  {region}
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Typography>
      <Typography variant="body1" fontWeight={500} mb={6}>
        Launch the CFN stack and return here to proceed after successful setup.
      </Typography>
      <Box mb={6}>
        <CloudFormationStackButton />
      </Box>
      <Typography variant="body1" fontWeight={500} mb={2}>
        You can close this windows to monitor deployment progress in the
        Deployment Dashboard.
      </Typography>
      <Typography variant="body1" fontWeight={500} mb={2}>
        Once the deployment is complete, Click "Link" from the deployment
        dashboard and follow the instructions to link your protected accounts to
        the Cloud Connector deployed in your dedicated account.
      </Typography>
      <Stack mb={2} direction="row" gap={1}>
        <CircleInfoIcon />
        <Typography variant="body1" fontWeight={500}>
          For reference, the CloudFormation template is available here for your
          inspection.
        </Typography>
      </Stack>
      <Box>
        <ReviewElastioButton />
      </Box>
    </Box>
  )
}

export default AwsAccountElastio
