import { useContext } from 'react'
import { ToastContext, ToastOptions } from 'ui-v2/src/providers/toast'

type ToastFunction = (message: string, options?: ToastOptions) => void

interface ToastUtils {
  success: ToastFunction
  error: ToastFunction
  warning: ToastFunction
  info: ToastFunction
}

export const useToast = (): ToastUtils => {
  const { addToast } = useContext(ToastContext)

  if (!addToast) {
    throw new Error('useToast must be used within a ToastProvider')
  }

  const createToast =
    (severity: ToastOptions['severity']) =>
    (message: string, options: ToastOptions = {}) =>
      addToast(message, {
        ...options,
        severity,
      })

  return {
    success: createToast('success'),
    error: createToast('error'),
    warning: createToast('warning'),
    info: createToast('info'),
  }
}
