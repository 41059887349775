import Box from '@mui/material/Box'
import ActiveDeploymentTable from './active-deployment-table'
import AwsDrawer from '../aws-drawer'
import UpdateDrawer from './drawers/update-drawer'
import UnlinkProtectedAccount from './drawers/unlink-protected-account'
import UnlinkCloudConnector from './drawers/unlink-cloud-connector'
import LinkProtectedAccount from './drawers/link-protected-account'

const ActiveDeployments = () => {
  return (
    <Box>
      <ActiveDeploymentTable />
      <AwsDrawer />
      <UpdateDrawer />
      <UnlinkProtectedAccount />
      <LinkProtectedAccount />
      <UnlinkCloudConnector />
    </Box>
  )
}

export default ActiveDeployments
